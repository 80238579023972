/**
*******************************************************************************
* Licensed Materials - Property of NWEA
* Copyright NWEA 2000-2020 All Rights Reserved
*******************************************************************************
*/

import lodashTransform from 'lodash/transform'
import {
  ObservableCallbacks,
  ConfigurationVariables,
  noopFunction,
} from '../renderer-constants'

const PROP_CLEANUP_START = 'cleanupStart'
const PROP_CLEANUP_FINISH = 'cleanupFinish'
const PROP_INITIALIZATION_START = 'initializationStart'
const PROP_INITIALIZATION_FINISH = 'initializationFinish'
const PROP_RENDERABLE_CONSTRUCTION_START = 'renderableConstructionStart'
const PROP_RENDERABLE_CONSTRUCTION_FINISH = 'renderableConstructionFinish'
const PROP_DOM_LOAD_START = 'domLoadStart'
const PROP_DOM_LOAD_FINISH = 'domLoadFinish'
const PROP_CONTENT_LOAD_FINISH = 'contentLoadFinish'

function presenterifyError (error) {
  console.warn('state tracker caught error:', error)
  const itemPresenterPrefix = 'Presenter'
  error.name = error.name || 'Error'
  if (error.name.indexOf(itemPresenterPrefix) === -1) {
    error.name = `${itemPresenterPrefix} ${error.name}`
  }
}

function __recordNow (stateTracker, propertyName) {
  stateTracker._timings[propertyName] = Date.now()
}

function __getTimingForProperty (stateTracker, startProperty, finishProperty) {
  const startTime = stateTracker._timings[startProperty] || 0
  const finishTime = stateTracker._timings[finishProperty] || 0

  return startTime && !finishTime ? Number.NaN : finishTime - startTime
}

export default class StateTracker {
  constructor () {
    this._timings = {}
    this._variables = {
      [ConfigurationVariables.ITEM_LOAD_SPINNER_DELAY]: 6000,
      [ConfigurationVariables.ITEM_LOAD_HELP_INSTRUCTIONS_DELAY]: 6000,
      [ConfigurationVariables.DISABLE_SPINNER_AND_INSTRUCTIONS_TIMEOUT]: false,
    }
    this._features = {}
    this._externalObservers = {
      domLoadObserver: noopFunction,
      domAndContentLoadObserver: noopFunction,
      runtimeErrorObserver: noopFunction,
      [ObservableCallbacks.SCROLL_OBSERVER]: noopFunction,
      [ObservableCallbacks.RESPONSE_OBSERVER]: noopFunction,
      [ObservableCallbacks.AUDIO_OBSERVER]: noopFunction,
      [ObservableCallbacks.TEXT_TO_SPEECH_AUDIO_OBSERVER]: noopFunction,
      [ObservableCallbacks.KEY_EVENT_OBSERVER]: noopFunction,
      [ObservableCallbacks.EASY_TARGETING_MODE_OBSERVER]: noopFunction,
      [ObservableCallbacks.ITEM_AIDS_PANEL_OBSERVER]: noopFunction,
      [ObservableCallbacks.USER_ACTIVITY_OBSERVER]: noopFunction,
    }
  }

  get observers () {
    return {
      [ObservableCallbacks.ITEM_CLEANUP_START_OBSERVER]: () => {
        this._lastAudioEvent = null
        this._lastScrollInformationSummary = null

        __recordNow(this, PROP_CLEANUP_START)

        this._timings[PROP_CLEANUP_FINISH] = null
      },
      [ObservableCallbacks.ITEM_CLEANUP_FINISH_OBSERVER]: __recordNow.bind(null, this, PROP_CLEANUP_FINISH),
      [ObservableCallbacks.ITEM_INITIALIZATION_START_OBSERVER]: () => {
        this._timings = {
          [PROP_CLEANUP_START]: this._timings[PROP_CLEANUP_START],
          [PROP_CLEANUP_FINISH]: this._timings[PROP_CLEANUP_FINISH],
          [PROP_INITIALIZATION_START]: Date.now(),
          [PROP_INITIALIZATION_FINISH]: null,
          [PROP_RENDERABLE_CONSTRUCTION_START]: null,
          [PROP_RENDERABLE_CONSTRUCTION_FINISH]: null,
          [PROP_DOM_LOAD_START]: null,
          [PROP_DOM_LOAD_FINISH]: null,
          [PROP_CONTENT_LOAD_FINISH]: null,
        }
      },
      [ObservableCallbacks.ITEM_INITIALIZATION_FINISH_OBSERVER]: __recordNow.bind(
        null,
        this,
        PROP_INITIALIZATION_FINISH
      ),
      [ObservableCallbacks.ITEM_RENDERABLE_CONSTRUCTION_START_OBSERVER]: __recordNow.bind(
        null,
        this,
        PROP_RENDERABLE_CONSTRUCTION_START
      ),
      [ObservableCallbacks.ITEM_RENDERABLE_CONSTRUCTION_FINISH_OBSERVER]: __recordNow.bind(
        null,
        this,
        PROP_RENDERABLE_CONSTRUCTION_FINISH
      ),
      [ObservableCallbacks.ITEM_DOM_LOAD_START_OBSERVER]: __recordNow.bind(null, this, PROP_DOM_LOAD_START),
      [ObservableCallbacks.ITEM_DOM_LOAD_FINISH_OBSERVER]: () => {
        __recordNow(this, PROP_DOM_LOAD_FINISH)
        this.domLoadObserver()
      },
      [ObservableCallbacks.ITEM_CONTENT_LOAD_FINISH_OBSERVER]: () => {
        __recordNow(this, PROP_CONTENT_LOAD_FINISH)
        this.domAndContentLoadObserver(true)
      },
      [ObservableCallbacks.ITEM_CONTENT_LOAD_ERROR_OBSERVER]: (renderable, e) => {
        console.error('Failed to load content', e)
        presenterifyError(e)
        __recordNow(this, PROP_CONTENT_LOAD_FINISH)
        this.domAndContentLoadObserver(false, e)
      },
      [ObservableCallbacks.ITEM_RUNTIME_ERROR_OBSERVER]: (e) => {
        presenterifyError(e)
        this.runtimeErrorObserver(e)
      },
      [ObservableCallbacks.RESPONSE_OBSERVER]: responseData => {
        this._lastResponse = responseData
        this.responseObserver(responseData)
      },
      [ObservableCallbacks.ITEM_AIDS_PANEL_OBSERVER]: panelData => {
        this._lastPanelState = panelData
        this.itemAidsPanelObserver(panelData)
      },
      [ObservableCallbacks.SCROLL_OBSERVER]: scrollData => {
        this._lastScrollInformationSummary = scrollData
        this.scrollObserver(scrollData)
      },
      [ObservableCallbacks.AUDIO_OBSERVER]: (audioEventName, data) => {
        this._lastAudioEvent = { name: audioEventName, data }
        this.audioObserver(audioEventName, data)
      },
      [ObservableCallbacks.TEXT_TO_SPEECH_AUDIO_OBSERVER]: (eventName) => {
        this.textToSpeechAudioObserver(eventName)
      },
      [ObservableCallbacks.KEY_EVENT_OBSERVER]: keyEventData => {
        this.keyEventObserver(keyEventData)
      },
      [ObservableCallbacks.EASY_TARGETING_MODE_OBSERVER]: (enabled) => {
        this.easyTargetingModeObserver(enabled)
      },
      [ObservableCallbacks.USER_ACTIVITY_OBSERVER]: eventData => {
        this.userActivityObserver(eventData)
      },
    }
  }

  get variables () {
    return this._variables
  }

  get features () {
    return this._features
  }

  get domLoadObserver () {
    return this._externalObservers.domLoadObserver
  }

  set domLoadObserver (domLoadObserver) {
    this._externalObservers.domLoadObserver = domLoadObserver || noopFunction
  }

  get domAndContentLoadObserver () {
    return this._externalObservers.domAndContentLoadObserver
  }

  set domAndContentLoadObserver (contentLoadObserver) {
    this._externalObservers.domAndContentLoadObserver = contentLoadObserver || noopFunction
  }

  get runtimeErrorObserver () {
    return this._externalObservers.runtimeErrorObserver
  }

  set runtimeErrorObserver (runtimeErrorObserver) {
    this._externalObservers.runtimeErrorObserver = runtimeErrorObserver || noopFunction
  }

  get responseObserver () {
    return this._externalObservers[ObservableCallbacks.RESPONSE_OBSERVER]
  }

  set responseObserver (responseObserver) {
    this._externalObservers[ObservableCallbacks.RESPONSE_OBSERVER] = responseObserver || noopFunction
  }

  get itemAidsPanelObserver () {
    return this._externalObservers[ObservableCallbacks.ITEM_AIDS_PANEL_OBSERVER]
  }

  set itemAidsPanelObserver (panelObserver) {
    this._externalObservers[ObservableCallbacks.ITEM_AIDS_PANEL_OBSERVER] = panelObserver || noopFunction
  }

  get scrollObserver () {
    return this._externalObservers[ObservableCallbacks.SCROLL_OBSERVER]
  }

  set scrollObserver (scrollObserver) {
    this._externalObservers[ObservableCallbacks.SCROLL_OBSERVER] = scrollObserver || noopFunction
  }

  get audioObserver () {
    return this._externalObservers[ObservableCallbacks.AUDIO_OBSERVER]
  }

  set audioObserver (audioObserver) {
    this._externalObservers[ObservableCallbacks.AUDIO_OBSERVER] = audioObserver || noopFunction
  }

  get textToSpeechAudioObserver () {
    return this._externalObservers[ObservableCallbacks.TEXT_TO_SPEECH_AUDIO_OBSERVER]
  }

  set textToSpeechAudioObserver (textToSpeechAudioObserver) {
    this._externalObservers[ObservableCallbacks.TEXT_TO_SPEECH_AUDIO_OBSERVER] =
      textToSpeechAudioObserver || noopFunction
  }

  get keyEventObserver () {
    return this._externalObservers[ObservableCallbacks.KEY_EVENT_OBSERVER]
  }

  set keyEventObserver (keyEventObserver) {
    this._externalObservers[ObservableCallbacks.KEY_EVENT_OBSERVER] = keyEventObserver || noopFunction
  }

  get userActivityObserver () {
    return this._externalObservers[ObservableCallbacks.USER_ACTIVITY_OBSERVER]
  }

  set userActivityObserver (userActivityObserver) {
    this._externalObservers[ObservableCallbacks.USER_ACTIVITY_OBSERVER] =
      userActivityObserver || noopFunction
  }

  get easyTargetingModeObserver () {
    return this._externalObservers[ObservableCallbacks.EASY_TARGETING_MODE_OBSERVER]
  }

  set easyTargetingModeObserver (easyTargetingModeObserver) {
    this._externalObservers[ObservableCallbacks.EASY_TARGETING_MODE_OBSERVER] = easyTargetingModeObserver || noopFunction
  }

  get lastResponse () {
    return this._lastResponse
  }

  get lastAudioEventNameAndData () {
    return this._lastAudioEvent
  }

  get scrollInformationSummary () {
    return this._lastScrollInformationSummary
  }

  get timings () {
    const result = {
      previousItemCleanup: __getTimingForProperty(this, PROP_CLEANUP_START, PROP_CLEANUP_FINISH),
      loadNewItemSources: __getTimingForProperty(this, PROP_INITIALIZATION_START, PROP_INITIALIZATION_FINISH),
      createNewRenderableObject: __getTimingForProperty(
        this,
        PROP_RENDERABLE_CONSTRUCTION_START,
        PROP_RENDERABLE_CONSTRUCTION_FINISH
      ),
      loadDom: __getTimingForProperty(this, PROP_DOM_LOAD_START, PROP_DOM_LOAD_FINISH),
      loadContent: __getTimingForProperty(this, PROP_DOM_LOAD_FINISH, PROP_CONTENT_LOAD_FINISH),
    }

    result.total = lodashTransform(
      result,
      (sumObj, timing) => {
        sumObj.sum += isNaN(timing) ? 0 : timing
        return sumObj
      },
      { sum: 0 }
    ).sum

    return result
  }

  getVariable (name) {
    return this._variables[name] || ''
  }

  setVariable (name, value) {
    this._variables[name] = value
  }

  clearVariable (name) {
    delete this._variables[name]
  }
}
