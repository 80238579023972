import { ConfigurationVariables, Languages } from '../../renderer-constants'
import * as english from './lang/en.js'
import * as spanish from './lang/es.js'

export default class Localizer {
  constructor (runtimeConfiguration) {
    const language = runtimeConfiguration.getVariable(
      ConfigurationVariables.LANGUAGE
    )

    switch (language) {
    case Languages.ENGLISH:
      this.localizedStrings = english
      break
    case Languages.SPANISH:
      this.localizedStrings = spanish
      break
    default:
      this.localizedStrings = english
      break
    }
  }

  getLocalizedData (stringId) {
    const localizedData = this.localizedStrings[stringId] || english[stringId]

    if (typeof localizedData === 'undefined') {
      throw new Error('Unknown localization key')
    }

    return localizedData
  }
}
