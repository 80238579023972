/**
*******************************************************************************
* Licensed Materials - Property of NWEA
* Copyright NWEA 2000-2020 All Rights Reserved
*******************************************************************************
*/

import lodashGet from 'lodash/get'
import lodashTransform from 'lodash/transform'

export default class RuntimeConfiguration {
  /**
   * <p>This constructs a new RuntimeConfiguration object that will validate the
   * configuration and house its results.</p>
   *
   * <p>Configuration currently comes in three flavors: <i>features</i>,
   * <i>variables</i>, <i>services</i>, and <i>observable events</i>.</p>
   *
   * <p><i><b>Features</b></i> represent toggles for renderer features like
   * keyboard navigation.</p>
   *
   * <p><i><b>Variables</b></i> represent global configuration values for things
   * like the media path, current item number, number of items in a "group",
   * etc.</p>
   *
   * <p><i><b>Services</b></i> (e.g. AudioPlayer)
   *
   * <p><i><b>Observables</b></i> holds callbacks that will be notified of
   * certain item state changes, such as the currentscore-able response string.
   * </p>
   *
   * @public
   * @constructor
   * @param runtimeConfigurationJson {{ [features]: Object, [variables]: Object, [observables]: Object }} JSON used to set run-time information
   */
  constructor (runtimeConfigurationJson) {
    this.variables = lodashGet(runtimeConfigurationJson, 'variables', {})
    this.observables = lodashGet(runtimeConfigurationJson, 'observables', {})
    this.features = lodashGet(runtimeConfigurationJson, 'features', {})
    this.__services = {}
    this.responders = {}
  }

  getVariable (variableName) {
    return this.variables[variableName]
  }

  setService (serviceName, service) {
    if (this.__services[serviceName]) {
      throw new Error(`Service ${serviceName} already exists`)
    }

    this.__services[serviceName] = service
  }

  getService (serviceName) {
    return this.__services[serviceName]
  }

  getObservable (eventName) {
    return this.observables[eventName]
  }

  getResponders (responderName) {
    return this.responders[responderName] || []
  }

  setFeatureEnabled (featureName, isEnabled, data) {
    if (isEnabled) {
      this.features[featureName] = data || {}
    } else {
      delete this.features[featureName]
    }
  }

  isFeatureEnabled (featureName) {
    return !!this.features[featureName]
  }

  getFeatureData (featureName) {
    return this.features[featureName] || null
  }

  addResponder (responderName, responderCallback) {
    if (typeof responderCallback === 'function') {
      if (!this.responders[responderName]) {
        this.responders[responderName] = [responderCallback]
      } else if (this.responders[responderName].indexOf(responderCallback) === -1) {
        this.responders[responderName].push(responderCallback)
      }
    }
  }

  removeResponder (responderName, responderCallback) {
    if (this.responders[responderName] && this.responders[responderName].length) {
      const indexOfResponderCallback = this.responders[responderName].indexOf(responderCallback)
      if (indexOfResponderCallback !== -1) {
        this.responders[responderName].splice(indexOfResponderCallback, 1)
      }
    }
  }

  /**
     * <p>Convenience method to return a new string replacing all occurrences of the string keys of the <i>substitutions</i>
     * variable with the variable values assigned as the value string for those keys.</p>
     *
     * @param string {String}
     * @param substitutions {Object}
     * @returns {String}
     */
  stringWithVariableSubstitutions (string, substitutions = {}) {
    return lodashTransform(
      substitutions,
      (state, variable, stringToReplace) => {
        if (this.variables[variable]) {
          state.result = state.result.replace(new RegExp(stringToReplace, 'g'), this.variables[variable])
        }
      },
      { result: string }
    ).result
  }
}
