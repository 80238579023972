import {
  ItemAids,
} from '../../../renderer-constants.js'

export const ItemAidUITitles = {
  [ItemAids.DESMOS_CALCULATOR]: {
    [ItemAids.DESMOS_CALCULATOR]: 'Calculator',
  },
  [ItemAids.DRIVER]: {
    [ItemAids.DRIVER]: 'Show Cats',
  },
  [ItemAids.HIGHLIGHTER]: {
    highlightItemAidButton: 'Highlighter',
    eraseItemAidButton: 'Erase Highlight',
    highlightPopupButton: 'Highlight Selection',
    erasePopupButton: 'Erase Highlights in Selection',
  },
  [ItemAids.IMAGE_VIEWER]: {
    [ItemAids.IMAGE_VIEWER]: 'Image Viewer',
  },
  [ItemAids.TEXT_TO_SPEECH]: {
    clickPlayButton: 'Select to play',
    playFromTopButton: 'Play from top',
    playPauseButton: 'Pause Audio',
    volumeButton: 'Volume',
    louderButton: 'Increase Volume',
    quieterButton: 'Reduce Volume',
  },
  [ItemAids.ELIM]: {
    [ItemAids.ELIM]: 'Answer Eliminator',
  },
  [ItemAids.LINE_READER]: {
    [ItemAids.LINE_READER]: 'Line Reader',
  },
  [ItemAids.NOTEPAD]: {
    [ItemAids.NOTEPAD]: 'Notepad',
  },
  [ItemAids.VOLUME]: {
    volumeButton: 'Volume',
    louderButton: 'Increase Volume',
    quieterButton: 'Reduce Volume',
  },
  [ItemAids.ZOOM]: {
    zoomOutButton: 'Zoom Out',
    zoomInButton: 'Zoom In',
    zoomLevel: 'Zoom Level',
  },
  // measurement handles its naming during construction
}

export const ActiveItemAidButtonTitles = {
  [ItemAids.TEXT_TO_SPEECH]: {
    playPauseButton: 'Play Audio',
  },
}

export const InactiveItemAidButtonTitles = {

}

export const DisabledItemAidButtonTitles = {
  [ItemAids.TEXT_TO_SPEECH]: {
    playPauseButton: 'Pause Audio. Disabled.',
    clickPlayButton: 'Select to play. Disabled.',
    playFromTopButton: 'Play from top. Disabled.',
  },
  [ItemAids.HIGHLIGHTER]: {
    highlightItemAidButton: 'Highlighter. Disabled.',
    eraseItemAidButton: 'Erase Highlight. Disabled.',
  },
}

export const MeasurementToolAidTitles = {
  protractor: 'Protractor',
  ruler: 'Ruler',
}

export const LoadingWarningDirective = {
  loadingWarning: 'The question is taking a long time to load.',
  loadingDirective: 'Raise your hand and ask for help.',
}

// <h1>, <h2>, etc tags
export const Headings = {
  answerOptions: 'Answer Options',
  blankSpace: 'Blank space',
  blankSpaces: 'blank spaces',
  box: 'box',
  boxes: 'boxes',
  directions: 'Directions',
  empty: 'empty',
  groups: 'groups',
  information: 'Information',
  question: 'Question',
  optionsHeadingStart: 'Answer options with',
  option: 'Option',
  options: 'options',
  radioButtonsWithin: 'radio buttons within',
  responseArea: 'Response area with',
  textInput: 'text input',
}

// decorator tags
export const DecoratorTags = {
  baseline: 'baseline',
  boldBegin: 'begin bold',
  bold: 'bold',
  boldEnd: 'end bold',
  emphasisBegin: 'begin emphasis',
  emphasis: 'Emphasis',
  emphasisEnd: 'end emphasis',
  fillInTheBlank: 'fill in the blank',
  italicsBegin: 'begin italics',
  italics: 'Italics',
  italicsEnd: 'end italics',
  superscript: 'superscript',
  subscript: 'subscript',
  underlineBegin: 'begin underline',
  underline: 'Underline',
  underlineEnd: 'end underline',
}

// item aid widget tags
export const MovableItemAidStrings = {
  hide: 'Hide',
  notepad: 'Notepad',
  referenceSheet: 'Reference Sheet',
  calculatorTool: 'Calculator tool',
}

// support tags
export const SupportTags = {
  beginFraction: 'begin fraction',
  longDescription: 'Long description',
  map: 'MAP',
  navigation: 'Navigation',
  paragraph: 'paragraph',
  passage: 'Passage',
  passages: 'Passages',
  question: 'Question',
  remove: 'Remove',
  shortDescription: 'Short description',
  showHideAnswer: 'Show/Hide Answer',
  spelling: 'spelling',
  spellingBegin: 'Begin Spelling',
  spellingEnd: 'End Spelling',
  toolbar: 'Toolbar',
}
