import {
  ItemAids,
} from '../../../renderer-constants.js'

export const ItemAidUITitles = {
  [ItemAids.DESMOS_CALCULATOR]: {
    [ItemAids.DESMOS_CALCULATOR]: 'Calculadora',
  },
  [ItemAids.HIGHLIGHTER]: {
    highlightItemAidButton: 'Resaltador',
    eraseItemAidButton: 'Borrar el resaltado',
  },
  [ItemAids.IMAGE_VIEWER]: {
    [ItemAids.IMAGE_VIEWER]: 'Visor de imagen', // not used in MAP?
  },
  [ItemAids.TEXT_TO_SPEECH]: {
    clickPlayButton: 'Selecciona para reproducir',
    playFromTopButton: 'Reproducir desde inicio',
    playPauseButton: 'Audio en pausa',
    volumeButton: 'Volumen',
    louderButton: 'Subir volumen',
    quieterButton: 'Bajar volumen',
  },
  [ItemAids.ELIM]: {
    [ItemAids.ELIM]: 'Eliminador de respuestas',
  },
  [ItemAids.LINE_READER]: {
    [ItemAids.LINE_READER]: 'GuÃ­a de lectura',
  },
  [ItemAids.NOTEPAD]: {
    [ItemAids.NOTEPAD]: 'Anotador',
  },
  [ItemAids.VOLUME]: {
    volumeButton: 'Volumen',
    louderButton: 'Subir volumen',
    quieterButton: 'Bajar volumen',
  },
  [ItemAids.ZOOM]: {
    zoomOutButton: 'Disminuir zoom',
    zoomInButton: 'Aumentar zoom',
    zoomLevel: 'Nivel de zoom',
  },
  // measurement handles its naming during construction
}

export const ActiveItemAidButtonTitles = {
  [ItemAids.TEXT_TO_SPEECH]: {
    playPauseButton: 'Reproducir audio',
  },
}

export const InactiveItemAidButtonTitles = {

}

export const DisabledItemAidButtonTitles = {
  [ItemAids.TEXT_TO_SPEECH]: {
    playPauseButton: 'Pausar audio. Desactivado',
    clickPlayButton: 'Seleccionar para reproducir. Desactivado',
    playFromTopButton: 'Reproducir desde inicio. Desactivado',
  },
  [ItemAids.HIGHLIGHTER]: {
    highlightItemAidButton: 'Resaltador. Desactivado',
    eraseItemAidButton: 'Borrar resaltado. Desactivado',
  },
}

export const MeasurementToolAidTitles = {
  protractor: 'Transportador',
  ruler: 'Regla',
}

export const LoadingWarningDirective = {
  loadingWarning: 'La pregunta estÃ¡ tardando mucho en cargar.',
  loadingDirective: 'Levanta la mano para pedir ayuda.',
}

// <h1>, <h2>, etc tags
export const Headings = {
  answerOptions: 'Opciones de Respuesta',
  blankSpace: 'Espacios de blanco',
  blankSpaces: 'espacios en blanco',
  box: 'caja',
  boxes: 'cajas',
  directions: 'Instrucciones',
  empty: 'vacia',
  groups: 'grupo',
  information: 'InformaciÃ³n',
  question: 'Pregunta',
  optionsHeadingStart: 'Opciones de respuesta con',
  option: 'OpciÃ³n',
  options: 'opciones',
  radioButtonsWithin: 'botones de radio dentro de',
  responseArea: 'Campo de respuesta con',
  textInput: 'cuadros de texto',
}

// decorator tags
export const DecoratorTags = {
  baseline: 'lÃ­nea de base',
  boldBegin: 'comenzar negrita',
  bold: 'Negrita',
  boldEnd: 'final negrita',
  emphasisBegin: 'comenzar Ã©nfasis',
  emphasis: 'Ãnfasis',
  emphasisEnd: 'final Ã©nfasis',
  fillInTheBlank: 'Espacio en blanco',
  italicsBegin: 'comenzar cursiva',
  italics: 'Cursiva',
  italicsEnd: 'final cursiva',
  superscript: 'superÃ­ndice',
  subscript: 'subÃ­ndice',
  underlineBegin: 'comenzar subrayado',
  underline: 'Subrayado',
  underlineEnd: 'final subrayado',
}

// item aid widget tags
export const MovableItemAidStrings = {
  hide: 'Ocultar',
  notepad: 'Anotador',
  referenceSheet: 'Hoja de referencia',
  calculatorTool: 'Herramienta de calculador', // todo: check this translation
}

// support tags
export const SupportTags = {
  beginFraction: 'Inicio fracciÃ³n',
  longDescription: 'DescripciÃ³n larga',
  map: 'MAPA',
  navigation: 'NavegaciÃ³n',
  paragraph: 'pÃ¡rrafo',
  passage: 'Pasaje',
  passages: 'Pasajes',
  question: 'Pregunta',
  remove: 'Eliminar',
  showHideAnswer: 'Mostrar/Ocultar respuesta',
  shortDescription: 'DescripciÃ³n corta',
  spelling: 'deletreo',
  spellingBegin: 'Comenzar OrtografÃ­a',
  spellingEnd: 'Final OrtografÃ­a',
  toolbar: 'Barra de herramientas',
}
