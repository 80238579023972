/**
*******************************************************************************
* Licensed Materials - Property of NWEA
* Copyright NWEA 2000-2020 All Rights Reserved
*******************************************************************************
*/

import 'core-js/stable'
import tabbable from 'tabbable'
import lodashGet from 'lodash/get'
import partial from 'lodash/partial'
import Freezer from 'freezer-js'
import focusWithin from 'focus-within'
import lodashThrottle from 'lodash/throttle'

import {
  loadItemExecutor,
  loadItemAidsExecutor,
  RESOURCE_PATH,
  PRESENTER_VERSION,
} from './resource-loader'
import RuntimeConfiguration from './runtime-configuration'
import StateTracker from './state-tracker'

import { default as ItemDefinitionModel } from '../item-definition-model'
import { default as ItemAidsPanelModel } from '../item-aids-panel-model'
import { default as ThemeDefinitionModel } from '../theme-definition-model'

import {
  ConfigurationVariables,
  ObservableCallbacks,
  RenderableEvents,
  ItemRenderableEvents,
  ResponderNames,
  FeatureNames,
  FreezerKeys,
  ItemAids,
  ProductNames,
  Languages,
  noopFunction,
  InteractionNames,
  ItemParts,
  ValidThemesByProduct,
  DefaultThemesByProduct,
  TTSExcludeGroups,
  TTSExcludeGroupNames,
  TabIndexValues,
} from '../renderer-constants'
import Localizer from '../common/localization'

/**
 * <p>This is *THE* module that is the API from a client to the "presenter". The
 * primary use-age pattern for the Presenter is:</p>
 *
 * <pre>
 * var presenter = require("presenter"),
 *     stateTracker = presenter.getPresenterStateTracker();
 * ...
 * stateTracker.setVariable(presenter.variables.MEDIA_PATH, '/path/to/media');
 * // for OECD only...
 * stateTracker.setVariable(renderer.variables.ITEM_SET_NAME, itemSetNameFromQtiManifest);
 * stateTracker.setVariable(renderer.variables.ITEM_SET_POSITION_IN_GROUP, itemPositionWithinSetForThisTest);
 * stateTracker.setVariable(renderer.variables.ITEM_SET_GROUP_SIZE, numberOfItemsWithinSetForThisTest);
 * ...
 * presenter.presentItem({ ... item editor JSON ... }, document.getElementById('item'));
 * </pre>
 *
 * @module PresenterApi
 */

let lastItemRenderable
let lastItemAidsPanelRenderable
let lastRuntimeConfiguration
let lastItemAidsPanelRuntimeConfiguration
let presenterContainer
let displayIframePromise
let loadedIframe
let presentItemPromise = Promise.resolve()
let presentItemAidsPanelPromise = Promise.resolve()
let volumeLevel
let easyTargetingMode
let spinnerTimeout
let showHelpTimeout

const TAB_KEY_CODE = 9
const STATE_TRACKER = new StateTracker()

const itemAidsSessionFreezer = new Freezer({})

const ITEM_CONTENT_LOADING = 'item-content-loading'
const ITEM_LOADER_PARKED = 'item-loader-parked'
const ITEM_LOADER_LOADING = 'item-loader-loading'
const ITEM_LOADER_LOADED = 'item-loader-loaded'
const ITEM_LOADER_CONTENT_SHORT_LOAD = 'item-loader-content-short-load'
const ITEM_LOADER_CONTENT_LONG_LOAD = 'item-loader-content-long-load'

const ITEM_PRESENTER_CDN_DEPRECATED = 'NWEA-ASSESSMENT-ITEM-PRESENTER-CDN-DEPRECATION-PLACEHOLDER'

if (ITEM_PRESENTER_CDN_DEPRECATED === true) {
  console.warn(
    'Accessing presenter-api.js via item.mapnwea.org is deprecated. ' +
    'The Item Presenter will no longer be accessible from item.mapnwea.org ' +
    'starting June 15th, 2022. Please contact the Item Experience team for ' +
    'more information.'
  )
}

// polyfill for CSS focus-within, needed for Edge and Safari 9-10
// used by Movable item aids
focusWithin(document)

function getDocument (iframe) {
  if (iframe) {
    return iframe.contentDocument || iframe.contentWindow.document
  }
}

function triggerVolumeChangeResponders () {
  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.getResponders(ResponderNames.AUDIO_VOLUME_CHANGE)
      .forEach(responder => {
        responder(volumeLevel)
      })
  })
}

function triggerMarkupChangedResponders () {
  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.getResponders(ResponderNames.MARKUP_CHANGED)
      .forEach(responder => {
        responder()
      })
  })
}

function triggerMarkupVisibilityChangedResponders () {
  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.getResponders(ResponderNames.MARKUP_VISIBILITY_CHANGED)
      .forEach(responder => {
        responder()
      })
  })
}

function triggerFeatureEnablerResponders (featureName, isEnabled, featureData) {
  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.getResponders(ResponderNames.FEATURE_ENABLER)
      .forEach(responder => {
        responder(featureName, isEnabled, featureData)
      })
  })
}

function observeKeyEvent (keyboardEvent) {
  // filter out ctrl-C and ctrl-V to prevent copying
  if (keyboardEvent.metaKey || keyboardEvent.ctrlKey) {
    switch (keyboardEvent.which) {
    case 67: /* c */
      keyboardEvent.preventDefault() // don't allow Copy
      break
    case 86: /* v */
      keyboardEvent.preventDefault() // don't allow Paste
      break
    default:
      break
    }
  }

  // if this event has already been trapped, or we are in a peculiar case where
  // the iframe has been disassociated from anything in the DOM but yet still
  // bubbles up an event, we want to do nothing
  if (keyboardEvent.defaultPrevented || !presenterContainer) {
    return
  }

  // handle tab events specially so that two things happen:
  // (1) the tab event itself is not broadcast out to key event listeners (so
  //     consumers, like Test Player, do not accidentally process this and
  //     result in double-tabbing)
  // (2) we try to detect if we are tabbing forward (e.g. no shift key) such
  //     that the user would expect focus to advance to the next element
  //     __outside of__ the iframe OR if we are tabbing backward (e.g. WITH a
  //     shift key) such that the user would expect focus to shift to the prior
  //     element __outside of__ the iframe
  if (keyboardEvent.which === TAB_KEY_CODE || keyboardEvent.key === 'Tab') {
    if (!lastItemRenderable) {
      return
    }

    const tabbableElements = tabbable(lastItemRenderable.domElement)
    const isLastIframeElement = keyboardEvent.target === tabbableElements[tabbableElements.length - 1]
    const isFirstIframeElement = keyboardEvent.target === tabbableElements[0]
    const tabbingForward = !keyboardEvent.shiftKey

    if ((tabbingForward && isLastIframeElement) ||
      (!tabbingForward && isFirstIframeElement)) {
      if (easyTargetingMode) {
        if (tabbingForward) {
          tabbableElements[0].focus()
        } else {
          tabbableElements[tabbableElements.length - 1].focus()
        }
        keyboardEvent.preventDefault()
      } else {
        presenterContainer.focus()
      }

      return false
    }
  } else if (lastRuntimeConfiguration) {
    // if this isn't a tab key, then we guard to make sure we have a
    // RuntimeConfiguration object that would hold a possible key event
    // observer, and notify that callback if we do.
    const observerCallback = lastRuntimeConfiguration.getObservable(
      ObservableCallbacks.KEY_EVENT_OBSERVER
    ) || noopFunction
    const keyEventData = {}

    // these are the properties defined to be copied over, per
    // 'renderer-constants.js'...
    ;['type', 'which', 'key', 'code', 'altKey', 'metaKey', 'ctrlKey', 'shiftKey', 'target'].forEach(property => {
      keyEventData[property] = keyboardEvent[property]
    })

    // This is to cover an anomaly (seen on Safari) which sometimes returns
    // 229 as the value of the which property for Alt-N.
    // In this particular case, we force a which value of 'N' (78) so that
    // test player can recognize this as an Alt-N hotkey
    // In both cases, the key is 'Dead'.
    // The which property is deprecated, but used for backward compatability to
    // Safari.  Someday, we must refactor to use the key property throughout.
    if (
      keyEventData.type === 'keydown' &&
      keyEventData.which === 229 &&
      keyEventData.key === 'Dead' &&
      keyEventData.code === 'KeyN' &&
      keyEventData.altKey === true
    ) {
      keyEventData.which = 78
    }

    observerCallback(keyEventData)
  }
}

// This is intended to allow Test Player to know if the user has interacted
// with the presenter, so it can show a prompt if the user is inactive
function observeUserActivity (event) {
  const observerCallback = (lastRuntimeConfiguration &&
    lastRuntimeConfiguration.getObservable(
    ObservableCallbacks.USER_ACTIVITY_OBSERVER
  )) || noopFunction

  observerCallback(event)
}

function observeError (error) {
  const observerCallback = lastRuntimeConfiguration.getObservable(
    ObservableCallbacks.ITEM_RUNTIME_ERROR_OBSERVER
  ) || noopFunction
  observerCallback(error)
}

/**
 * @private
 * @method getObserverAndDeleteFromConfig
 * @param runtimeConfigurationJson {Object}
 * @param observerName {String}
 * @returns {Function}
 */
function getObserverAndDeleteFromConfig (
  runtimeConfigurationJson,
  observerName
) {
  const result = lodashGet(runtimeConfigurationJson, ['observables', observerName])

  if (typeof result !== 'undefined' && delete runtimeConfigurationJson.observables[observerName]) {
    return result || noopFunction
  }

  return noopFunction
}

/**
 * @private
 * @method createRootRenderable
 * @param itemDefinitionJson {Object}
 * @param runtimeConfigurationJson {Object}
 * @param iframe {Element} the iframe element where the item will be
 * @param itemId {String} The id of the item to be presented.
 * @return {Promise}
 **/
export function createRootRenderable (itemDefinitionJson, iframe, runtimeConfigurationJson, itemId, product, theme) {
  const itemInitStartObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_INITIALIZATION_START_OBSERVER
  )
  const itemInitFinishObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_INITIALIZATION_FINISH_OBSERVER
  )
  const cleanupStartObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_CLEANUP_START_OBSERVER
  )
  const cleanupFinishObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_CLEANUP_FINISH_OBSERVER
  )
  const renderableCtorStartObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_RENDERABLE_CONSTRUCTION_START_OBSERVER
  )
  const renderableCtorFinishObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_RENDERABLE_CONSTRUCTION_FINISH_OBSERVER
  )
  const domLoadStartObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_DOM_LOAD_START_OBSERVER
  )
  const domLoadFinishObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_DOM_LOAD_FINISH_OBSERVER
  )
  const contentLoadFinishObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_CONTENT_LOAD_FINISH_OBSERVER
  )
  const contentLoadErrorObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.ITEM_CONTENT_LOAD_ERROR_OBSERVER
  )
  const easyTargetingModeObserver = getObserverAndDeleteFromConfig(
    runtimeConfigurationJson,
    ObservableCallbacks.EASY_TARGETING_MODE_OBSERVER
  )

  const newRuntimeConfiguration = new RuntimeConfiguration(
    runtimeConfigurationJson,
  )

  let actualProductThatWillBeUsed
  const overrideProductName = newRuntimeConfiguration.getVariable(ConfigurationVariables.PRODUCT_NAME)

  if (product) {
    actualProductThatWillBeUsed = product
  } else if (overrideProductName) {
    console.warn('This method of overriding the product is deprecated. Please use the \'product\' parameter of presentItem/presentItemAidsPanel instead')
    actualProductThatWillBeUsed = overrideProductName
  } else {
    console.warn('item-based theme display is deprecated and will eventually be removed.')
    actualProductThatWillBeUsed = itemDefinitionJson.product
  }

  const itemDefinitionModel =
    new ItemDefinitionModel(itemDefinitionJson, actualProductThatWillBeUsed)

  itemInitStartObserver()

  const containerDocument = iframe.contentDocument || iframe.contentWindow.document
  const containerWindow = iframe.contentWindow
  const themeDefinitionModel = new ThemeDefinitionModel(actualProductThatWillBeUsed, theme)

  return loadItemExecutor(containerDocument, containerWindow, itemDefinitionModel,
    themeDefinitionModel, newRuntimeConfiguration)
    .then(() => {
      itemInitFinishObserver()

      renderableCtorStartObserver()

      const ItemRenderable = iframe.contentWindow.require('common').ItemRenderable
      lastRuntimeConfiguration = newRuntimeConfiguration

      // here we construct a new renderable add on observers that equate
      // internal renderable events to externally friendly item-level events
      // that can be observed. Ideally we would create a freezer object and pass
      // that to the renderable, but passing it through (most likely because of
      // the iframe barrier (unverified), it is not working properly.
      /* eslint-disable new-cap */
      const renderable = new ItemRenderable(
        lastRuntimeConfiguration,
        itemDefinitionJson,
        itemDefinitionModel,
        itemId,
      )
      /* eslint-enable new-cap */

      renderable.on(RenderableEvents.RENDER_START, domLoadStartObserver)
      renderable.on(RenderableEvents.RENDER_FINISH, domLoadFinishObserver)
      renderable.on(RenderableEvents.CONTENT_READY, contentLoadFinishObserver)
      renderable.on(
        RenderableEvents.CONTENT_LOAD_ERROR,
        contentLoadErrorObserver
      )
      renderable.on(RenderableEvents.DISPOSE_START, cleanupStartObserver)
      renderable.on(RenderableEvents.DISPOSE_FINISH, cleanupFinishObserver)
      renderable.on(ItemRenderableEvents.CHANGED_EASY_TARGETING_MODE, (renderable, didEnableEasyTargetingMode) => {
        easyTargetingMode = didEnableEasyTargetingMode
        setFeatureEnabled(FeatureNames.EASY_TARGETING_MODE, didEnableEasyTargetingMode)
        easyTargetingModeObserver(easyTargetingMode)
      })

      renderable.on(RenderableEvents.RENDERABLES_ADDED, triggerMarkupChangedResponders)
      renderable.on(RenderableEvents.RENDERABLES_REMOVED, triggerMarkupChangedResponders)
      renderable.on(RenderableEvents.RENDERABLES_BECAME_VISIBLE, triggerMarkupVisibilityChangedResponders)

      // since each renderable sets-up/tears-down its own responder instances,
      // we have to notify a freshly constructed instance of the default volume
      // level
      triggerVolumeChangeResponders()

      renderableCtorFinishObserver()

      return renderable
    })
}

/**
 * <p>This detects if we are tabbing forward from (or backward onto) the element
 * containing the presenter iframe. If we are, then this shifts focus to the
 * first (or last, respectively) element in the iframe.</p>
 *
 * @private
 * @method keyUpTabHandler
 * @param e {Event} keyup event
 */
function keyUpTabHandler (e) {
  if (
    (e.key === 'Tab' || e.which === TAB_KEY_CODE) &&
    document.activeElement === presenterContainer &&
    lastItemRenderable
  ) {
    const tabbableElements = tabbable(lastItemRenderable.domElement)
    if (e.shiftKey) {
      tabbableElements[tabbableElements.length - 1].focus()
    } else {
      tabbableElements[0].focus()
    }
  }
}

/**
 * We want to suppress the context menu so students can't copy/paste. This is a
 * test security issue. Also, we don't want them to access the system dictionary
 * or any other tools that might be available.
 * @param e
 */
function contextMenuHandler (e) {
  e.preventDefault()
  return false
}

export function itemContentLoading (itemContentDiv, itemLoaderDiv) {
  itemContentDiv.classList.add(ITEM_CONTENT_LOADING)
  itemContentDiv.classList.remove('item-content-loaded')

  itemLoaderDiv.classList.add(ITEM_LOADER_PARKED)
  itemLoaderDiv.classList.remove(ITEM_LOADER_LOADING)
  itemLoaderDiv.classList.remove(ITEM_LOADER_LOADED)

  const loaderContent = itemLoaderDiv.querySelector('[data-id="item-loader-content"]')
  loaderContent.classList.add(ITEM_LOADER_CONTENT_SHORT_LOAD)
  loaderContent.classList.remove(ITEM_LOADER_CONTENT_LONG_LOAD)
}

export function itemContentLoaded (doc) {
  const content = doc.querySelector('#item-presenter')
  content.classList.add('item-content-loaded')
  content.classList.remove(ITEM_CONTENT_LOADING)

  const loader = doc.querySelector('#item-loader')
  loader.classList.remove(ITEM_LOADER_PARKED)
  loader.classList.remove(ITEM_LOADER_LOADING)
  loader.classList.add(ITEM_LOADER_LOADED)

  const loaderContent = loader.querySelector('[data-id="item-loader-content"]')
  loaderContent.classList.add(ITEM_LOADER_CONTENT_SHORT_LOAD)
  loaderContent.classList.remove(ITEM_LOADER_CONTENT_LONG_LOAD)
}

export function showLongDelay (itemLoaderContent) {
  itemLoaderContent.classList.remove(ITEM_LOADER_CONTENT_SHORT_LOAD)
  itemLoaderContent.classList.add(ITEM_LOADER_CONTENT_LONG_LOAD)
}

export function showShortDelay (delayedFn, itemLoader) {
  if (itemLoader.classList.contains(ITEM_LOADER_PARKED)) {
    itemLoader.classList.remove(ITEM_LOADER_PARKED)
    itemLoader.classList.add(ITEM_LOADER_LOADING)
    itemLoader.classList.remove(ITEM_LOADER_LOADED)
    const text = itemLoader.querySelector('[data-id="item-loader-content"]')
    setShowHelpTimeout(delayedFn(text))
  }
}

export function createItemContentEl (doc) {
  const el = doc.createElement('div')
  el.setAttribute('id', 'item-presenter')
  // Because the loading content is added before stylesheets are loaded, we can
  // see the loading message content. So we start off hidden.
  el.style.visibility = 'hidden'
  el.classList.add(ITEM_CONTENT_LOADING)
  return el
}

export function createItemLoadingEl (doc) {
  const el = doc.createElement('div')
  const runtimeConfiguration = new RuntimeConfiguration(STATE_TRACKER)
  const localizer = new Localizer(runtimeConfiguration)
  const loadingWarningDirective = localizer.getLocalizedData('LoadingWarningDirective')
  const loadingWarningMessage = loadingWarningDirective.loadingWarning
  const loadingDirectiveMessage = loadingWarningDirective.loadingDirective

  el.setAttribute('id', 'item-loader')
  el.classList.add(ITEM_LOADER_PARKED)
  // Because the loading content is added before stylesheets are loaded, we can
  // see the loading message content. So we start off hidden.
  el.style.display = 'none'
  el.innerHTML = `
<div class="item-loader-progress-indicator"></div>
<div
  data-id="item-loader-content"
  class="item-loader-content-short-load"
  role="alert"
>
  <strong>${loadingWarningMessage}
  <br/>${loadingDirectiveMessage}</strong>
  <br/>
  <br/>
  (Press <strong>F5 (Windows) or Cmd + R (Mac) </strong> to try loading this
  question again. This <strong>item display</strong> message may be caused by
  network connectivity problems; if you keep getting this message, check your
  network connection.)
</div>
`
  return el
}

export function displayIframeInContainer (element) {
  presenterContainer = element

  element.style['display'] = 'flex'
  element.style['min-height'] = '540px'

  // we explicitly set the tabindex of the element containing the iframe here so
  // that it will receive the right sequencing in the global tab-index rules
  // NWEA components share (where the renderer/ presenter own roughly 1000-2000)
  element.setAttribute('tabindex', TabIndexValues.IFRAME_CONTAINER.toString())

  if (!displayIframePromise) {
    displayIframePromise = new Promise((resolve, reject) => {
      const presenterIframe = document.createElement('iframe')

      // this name is set primarily for the benefit of functional-tests, which
      // need to execute user actions within the iframe and depend on a
      // constistent name for look-up
      presenterIframe.setAttribute('name', 'item-presenter')

      // this is needed to prevent the iframe from scrolling on single panel
      // items, so we don't end up with unexpected double scroolbars, since
      // overflow: hidden doesn't always cut it.
      presenterIframe.setAttribute('scrolling', 'no')

      // this is to make scrolling functional on ipads.  When laid out the
      // iframe is not correctly taking up 100% of the parent container, and is
      // in fact allowed to be much larger.  This combination of styles
      // effectively forces it to be no larger than its container.
      presenterIframe.style.height = '1px'
      presenterIframe.style.width = '1px'
      presenterIframe.style.minHeight = '100%'
      presenterIframe.style.minWidth = '100%'

      // we set this so that the iframe itself will not be a target of tabbing,
      // and by doing so the browser will naturally not stop on the iframe when
      // cycling through elements (which it would otherwise do by default after
      // tabbing through all non-iframe content)
      presenterIframe.setAttribute('tabindex', '-1')

      // this enables the execution of the scripts we'll be loading, as well as
      // allowing us to reach into the iframe and alter its content (which we'll
      // also be doing a lot of)
      presenterIframe.setAttribute('sandbox', 'allow-same-origin allow-scripts')

      // and here we set-up the basic styles about our iframe that enable it to
      // behave somewhat reacively and mostly invisibly to the containing app
      presenterIframe.style['border-style'] = 'none'
      presenterIframe.style['border-width'] = 0
      presenterIframe.style['-webkit-flex'] = '1'
      presenterIframe.style['flex'] = '1'
      presenterIframe.style['overflow'] = 'hidden'

      // lastly, we need to do some final set-up inside of the iframe, but this
      // is only safe to do once the browser has reported that the iframe DOM is
      // loaded--were we to attempt this too early, we'd find that the iframe
      // document does not yet exist
      presenterIframe.addEventListener('load', () => {
        const iframeDocument = getDocument(presenterIframe)
        const itemPresenterDiv = createItemContentEl(iframeDocument)
        const itemLoadingEl = createItemLoadingEl(iframeDocument)

        // In order to simulate "natural" tabbing into and out of the iframe, we
        // need to redirect certain tab key events that emanate from the window
        // level (as this does) as well as ones that are emitted from the iframe
        // (which is done in observeKeyEvent) so that we switch focus
        // into/out-of the iframe
        presenterContainer.addEventListener('keyup', keyUpTabHandler)

        // Any uncaught error should be observed by the test player so it can
        // log the error to the appropriate place
        presenterIframe.contentWindow.addEventListener('error', observeError)

        // When the iframe is first loaded, we attach a listener for the
        // 'keydown' and 'keypress' events so that we can report untrapped DOM
        // events to outside observers.
        iframeDocument.body.addEventListener('keydown', observeKeyEvent)
        iframeDocument.body.addEventListener('keypress', observeKeyEvent)

        // We want to report user activity to outside observers
        const throttledObserver = lodashThrottle(
          observeUserActivity || noopFunction,
          1000)
        iframeDocument.body.addEventListener('keydown', throttledObserver)
        iframeDocument.body.addEventListener('mousemove', throttledObserver)
        iframeDocument.body.addEventListener('click', throttledObserver)
        iframeDocument.body.addEventListener('touchstart', throttledObserver)
        iframeDocument.body.addEventListener('wheel', throttledObserver)

        // to prevent students from getting to the right-click menu and
        // accessing copy/paste/dictionary and other tools, we intercept
        // the contextmenu event anywhere in the iframe
        iframeDocument.body.addEventListener('contextmenu', contextMenuHandler)

        // In order for the flex-box styles applied to the #item-presenter div
        // we just created, the parent div (which in this case is the one
        // provided to us by the container) MUST have is display property set to
        // flex.
        iframeDocument.body.style.display = '-webkit-flex' // For Safari and
                                                           // Firefox
        iframeDocument.body.style.display = 'flex' // For Chrome
        iframeDocument.body.style.margin = '0px'

        // The item loading content must be appended first so it can act
        // as an overlay using position: absolute.
        iframeDocument.body.appendChild(itemPresenterDiv)
        iframeDocument.body.appendChild(itemLoadingEl)
        iframeDocument.title = 'main content'

        loadedIframe = presenterIframe

        // It is important that this promise be resolved only when the iframe
        // has reported it is loaded. FireFox appears to load some default blank
        // URL for an iframe without a src that results in the iframe contents
        // being erased in between the steps that add dependent scripts and the
        // one that constructs a new renderable from those presumed loaded
        // scripts (whereas Chrome appears to not disturb the iframe at all if
        // no src attribute is provided). By waiting for the iframe to load, we
        // can safely muck with dynamically loaded script tags without fear that
        // the DOM will vanish beneath our feet.
        resolve(presenterIframe)
      })

      element.appendChild(presenterIframe)
    })
  }
  return displayIframePromise
}

function presenterifyError (error) {
  console.warn('presenter-api caught error:', error)
  const itemPresenterPrefix = 'Presenter'
  error.name = error.name || 'Error'
  if (error.name.indexOf(itemPresenterPrefix) === -1) {
    error.name = `${itemPresenterPrefix} ${error.name}`
  }
  throw error
}

/**
 * <p>This will remove all DOM elements from the last item presented (if any),
 * and will load and draw the item defined by the input JSON. This returns a
 * promise that is resolved when the DOM has been successfully loaded (but
 * before any assets referenced by the item--such as images and audio--are
 * guaranteed to have finished loading). It will be implicitly rejected if an
 * exception occurs during this process. </p>
 *
 * @public
 * @method presentItem
 * @param itemDefinition {Object} JSON produced by the QTI compiler "de-compile"
 * step
 * @param target {Element|String} the native DOM element, or the id for it,
 * where the item will be shown
 * @param waitToDisplayPromise {Promise} A promise that must be resolved before
 * the item will be shown.
 * @param itemId {String} The id of the item to be presented.
 * @return {Promise}
 **/
export function presentItem (itemDefinition, target, waitToDisplayPromise, itemId, product, theme) {
  if (typeof target === 'string') {
    target = document.getElementById(target)
  }
  if (!target) {
    throw new Error(`No element provided or found for id of: ${target}`)
  }

  stopAudio()
  return displayIframeInContainer(target)
  .then((iframeNode) => {
    const presentItemDone = partial(
      displayItem,
      itemDefinition,
      iframeNode,
      waitToDisplayPromise,
      itemId,
      product,
      theme
    )
    presentItemPromise = presentItemPromise.then(
      presentItemDone,
      // If our last attempt at presenting was rejected, we still want to chain
      // another attempt, so we provide displayItem as our resolve and reject.
      presentItemDone
    )
    return presentItemPromise
  })
  .catch(presenterifyError)
}

/**
 * <p>This will remove all DOM elements from the last panel presented (if any),
 * and will load and draw the item defined by the input JSON. This returns a
 * promise that is resolved when the DOM has been successfully loaded and all
 * assets referenced by the panel--such as images and audio--are guaranteed to
 * have finished loading). It will be implicitly rejected if an exception occurs
  * during this process. </p>
 *
 * @public
 * @method presentItemAidsPanel
 * @param product {String} A product name
 * @param itemAidsConfiguration {Array} an array of item aid configurations
 * @param target {Element|String} the native DOM element, or the id for it,
 * where the item will be shown
 * @return {Promise}
 **/
export function presentItemAidsPanel (product, itemAidsConfiguration, target) {
  if (typeof target === 'string') {
    target = document.getElementById(target)
  }
  if (!target) {
    throw new Error(`No element provided or found for id of: ${target}`)
  }
  const loadPanel = () => {
    return loadItemAidsPanel(product, itemAidsConfiguration, target)
  }
  presentItemAidsPanelPromise = presentItemAidsPanelPromise.then(
    loadPanel,
    loadPanel
  )
  return presentItemAidsPanelPromise.catch(presenterifyError)
}

function windowRequireDefault (moduleName) {
  return window.require(moduleName).default
}

export function loadItemAidsPanel (product, itemAidsConfiguration, target) {
  const runtimeConfiguration = new RuntimeConfiguration({
    features: Object.assign(
      {},
      STATE_TRACKER.features,
    ),
    variables: Object.assign(
      {},
      {
        [ConfigurationVariables.PRODUCT_NAME]: product,
        [ConfigurationVariables.RESOURCE_PATH]: RESOURCE_PATH,
      },
      STATE_TRACKER.variables,
    ),
    observables: Object.assign(
      {},
      STATE_TRACKER.observers,
    ),
  })
  const itemAidsPanelModel = new ItemAidsPanelModel(
    runtimeConfiguration.getVariable(ConfigurationVariables.PRODUCT_NAME),
    itemAidsConfiguration
  )

  if (lastItemAidsPanelRenderable) {
    try {
      lastItemAidsPanelRenderable.dispose()
    } catch (e) {
      console.error(
        'Error disposing',
        lastItemAidsPanelRenderable,
        'but continuing anyways.',
        e
      )
    }
    lastItemAidsPanelRenderable = null
  }
  target.innerHTML = ''

  return loadItemAidsExecutor(document, window, itemAidsPanelModel, runtimeConfiguration)
  .then(() => {
    const ContainerItemAid = windowRequireDefault('containerItemAid')
    lastItemAidsPanelRuntimeConfiguration = runtimeConfiguration

    const renderable = new ContainerItemAid(
      runtimeConfiguration,
      itemAidsPanelModel,
      itemAidsSessionFreezer,
    )
    renderable.render(target)
    lastItemAidsPanelRenderable = renderable
    return new Promise((resolve, reject) => {
      renderable.on(RenderableEvents.CONTENT_READY, resolve)
      renderable.on(RenderableEvents.CONTENT_LOAD_ERROR, reject)
    })
  })
}

/**
 * @function prepareForItem - prepares the iframe to load the item.
 * @param iframeEl {Element} the iframe element where the item will be
 * displayed.
 * @return {Object} an object with the content element and loading element.
 */
export function prepareForItem (iframeEl) {
  cleanupLoadingIndicatorTimers()
  const iframeDocument = getDocument(iframeEl)
  const itemContentEl = iframeDocument.getElementById('item-presenter')
  const itemLoaderEl = iframeDocument.getElementById('item-loader')
  cleanupLastItem(itemContentEl)
  itemContentLoading(itemContentEl, itemLoaderEl)
  return {itemContentEl, itemLoaderEl, iframeDocument}
}

/**
 * @function loadRenderable - displays the provided renderable and handles any
 * loading related cleanup.
 * @param iframeEl {Element} the iframe element to render against.
 * @param iframeDoc {Document} the iframe document to render against.
 * @param itemContentEl {Element} the content element where the item is
 * presented.
 * @param itemLoaderEl {Element} the loader element where the loading
 * information is displayed.
 * @param waitToDisplayPromise {Promise} A promise that must be resolved before
 * the item will be shown.
 * @param renderable {RenderableBase} the renderable to load.
 * @returns nothing
 */
export function loadRenderable (
  iframeEl,
  iframeDoc,
  itemContentEl,
  itemLoaderEl,
  waitToDisplayPromise,
  renderable
) {
  setupLoadingIndicatorTimers(itemLoaderEl)
  displayRenderable(renderable, itemContentEl, iframeDoc, waitToDisplayPromise)
  iframeEl.focus()
}

/**
 * @function displayItem - displays an item and handles any loading logic
 * associated with the display and loading.
 * @param itemDefinition {Object} the definition of the item to display.
 * @param iframeNode {Element} the iframe node to render against.
 * @param waitToDisplayPromise {Promise} A promise that must be resolved before
 * the item will be shown.
 * @param itemId {String} The id of the item to be presented.
 */
export function displayItem (
  itemDefinition,
  iframeNode,
  waitToDisplayPromise,
  itemId,
  product,
  theme
) {
  const {
    itemContentEl,
    itemLoaderEl,
    iframeDocument,
  } = prepareForItem(iframeNode)

  // set current language for screen reader
  const language = STATE_TRACKER.getVariable(ConfigurationVariables.LANGUAGE) || 'en'

  itemContentEl.setAttribute('lang', language)
  itemLoaderEl.setAttribute('lang', language)

  return createRootRenderable(
    itemDefinition,
    iframeNode,
    {
      observables: STATE_TRACKER.observers,
      variables: STATE_TRACKER.variables,
      features: STATE_TRACKER.features,
    },
    itemId,
    product,
    theme,
  )
    .then(partial(
      loadRenderable,
      iframeNode,
      iframeDocument,
      itemContentEl,
      itemLoaderEl,
      waitToDisplayPromise,
    ))
}

export function setupLoadingIndicatorTimers (itemLoaderDiv) {
  // see if no spinner or instruction timers are requested
  if (STATE_TRACKER.variables.disableSpinnerAndInstructions) {
    return
  }

  // wire up timers
  const delayedShowHelpInstructions = partial(
    setTimeout,
    showLongDelay,
    STATE_TRACKER.variables.itemLoadHelpInstructionsDelay
  )
  const delayedShowSpinner = partial(
    setTimeout,
    partial(showShortDelay, delayedShowHelpInstructions),
    STATE_TRACKER.variables.itemLoadSpinnerDelay
  )
  // create the spinner (which, in turn makes help instructions)
  setSpinnerTimeout(delayedShowSpinner(itemLoaderDiv))
}

export function cleanupLoadingIndicatorTimers () {
  clearTimeout(spinnerTimeout)
  clearTimeout(showHelpTimeout)
}

export function setSpinnerTimeout (_spinnerTimeout) {
  spinnerTimeout = _spinnerTimeout
}

export function setShowHelpTimeout (_showHelpTimeout) {
  showHelpTimeout = _showHelpTimeout
}

export function displayRenderable (renderable, itemContentDiv, iframeDocument, waitToDisplayPromise) {
  const contentReadyPromise = new Promise((resolve, reject) => {
    renderable.on(RenderableEvents.CONTENT_READY, resolve)
  })
  Promise.all([
    contentReadyPromise,
    waitToDisplayPromise || Promise.resolve(),
  ])
  .then(() => {
    itemContentLoaded(iframeDocument)

    const configurations = [lastItemAidsPanelRuntimeConfiguration].filter(i => i)
    configurations.forEach((configuration) => {
      configuration.getResponders(ResponderNames.CONTENT_READY)
        .forEach(responder => {
          responder()
        })
    })
  })
  .catch(observeError)

  renderable.render(itemContentDiv)

  setLastRenderableRendered(renderable)
}

export function setLastRenderableRendered (renderable) {
  lastItemRenderable = renderable
}

export function cleanupLastItem (itemContentEl) {
  if (lastItemRenderable) {
    try {
      lastItemRenderable.dispose()
    } catch (e) {
      console.error(
        'Error disposing',
        lastItemRenderable,
        'but continuing anyways.',
        e
      )
    }
    lastItemRenderable = null
  }
  itemContentEl.innerHTML = ''
}

/**
 * <p>This returns a copy of the item definition JSON amended with an additional
 * property that captures the display state of this item, such that if the JSON
 * returned here is provided to presentItem again, the item will be displayed in
 * logically the same state as it was when this function was called.</p>
 *
 * <p>If no item has been presented yet, then undefined is (implicitly)
 * returned.</p>
 *
 * @public
 * @method getItemDefinitionSnapshot
 * @return {Object} new definition JSON
 **/
export function getItemDefinitionSnapshot () {
  if (lastItemRenderable) {
    return lastItemRenderable.toJson()
  }
}

/**
 * <p>Given an interaction name (see renderer-constants), this will return the
 * appropriate StateManager to the caller.  The idea being that the caller could
 * then set up a valid state to pass back to the presenter via rendererState
 *
 * @public
 * @method getInteractionStateFromScoring
 * @param {string} interactionType - the interaction name
 * @param {ScoringData} scoringData
 * @return {StateManager} the associated state manager for the interaction type
 **/

export function getInteractionStateFromScoring (
  interaction,
  itemPartDefinition,
  scoringData) {
  let interactionType = interaction.interactionType
  if (!Object.values(InteractionNames).some(v => v === interactionType)) {
    throw new Error(`Interaction type ${interactionType} doesn't exist`)
  }

  if (loadedIframe) {
    if (interactionType === InteractionNames.GRAPHIC_GAP_MATCH) {
      interactionType = InteractionNames.GAP_MATCH
    }
    let StateManager
    try {
      StateManager = loadedIframe.contentWindow.require(interactionType).StateManager
    } catch (error) {
      throw new Error(`StateManager not implemented for interaction type ${interactionType}`)
    }

    return StateManager
      .initializeStateFromScoring(scoringData, itemPartDefinition, interaction)
      .state
  }
}

/**
 * <p>This method should be called to completely "unmount" the presenter, and
 * any DOM/in-memory artifacts from the most recently displayed item. Front-end
 * applications should call this when the presenter is no longer needed.</p>
 *
 * @public
 * @method teardownPresenter
 **/
export function teardownPresenter () {
  if (lastItemRenderable) {
    stopAudio()

    lastItemRenderable.dispose()
    lastItemRenderable = null
  }

  if (presenterContainer) {
    presenterContainer.removeEventListener('keyup', keyUpTabHandler)
    presenterContainer = null
  }

  if (displayIframePromise) {
    displayIframePromise.then(iframe => iframe.parentNode.removeChild(iframe))
    displayIframePromise = null
    loadedIframe = null
  }
}

/**
 * <p>This method should be called to completely "unmount" the item aids panel,
 * and any DOM/in-memory artifacts from the most recently displayed panel.
 * Front-end applications should call this when the panel is no longer
 * needed.</p>
 *
 * @public
 * @method teardownItemAidsPanel
 **/
export function teardownItemAidsPanel () {
  if (lastItemAidsPanelRenderable) {
    lastItemAidsPanelRenderable.reset()
    lastItemAidsPanelRenderable.dispose()
    lastItemAidsPanelRenderable = null

    if (itemAidsSessionFreezer) {
      itemAidsSessionFreezer.set({}) // reset freezer to initial empty state
    }
  }
  // note: leave the promise intact in case we need to display the item aids
  // panel again
}

/**
 * <p>Returns a singleton instance of a StateTracker object that will provide
 * render life-cycle timings to the caller, as well as track the most recent
 * response state.</p>
 *
 * @public
 * @method getPresenterStateTracker
 * @returns {StateTracker} a state tracker instance
 */
export function getPresenterStateTracker () {
  return STATE_TRACKER
}

/**
 * <p>This sets the volume level for future audio files (as well as any
 * currently playing audio). If the input volume level does not fall between 0.0
 * and 1.0 (inclusive on both sides), then any value above 1.0 will be capped at
 * 1.0 and any value smaller than 0.0 will be promoted up to 0.0. If the input
 * is not a number and cannot be parsed into a floating point number, then no
 * action is taken. A warning will be written to the console of any input that
 * was ignored or not within range.</p>
 *
 * @public
 * @method setAudioVolume
 * @param newAudioVolume {Number} a number between 0.0 and 1.0
 **/
export function setAudioVolume (newAudioVolume) {
  const newVolumeLevel = parseFloat(newAudioVolume)

  if (!isNaN(newVolumeLevel)) {
    volumeLevel = newVolumeLevel > 1.0
      ? 1.0
      : newVolumeLevel < 0.0
        ? 0.0
        : newVolumeLevel

    triggerVolumeChangeResponders()
  }

  if (volumeLevel !== parseFloat(newAudioVolume)) {
    console.error('WARNING: call to "setAudioVolume()" was given an out-of-range volume value: ' + newAudioVolume)
  }
}

/**
 * <p>This returns the last set audio volume (or undefined if nothing has been
 * set).</p>
 *
 * @public
 * @method getAudioVolume
 * @returns {Number} the current audio volume, which is a number between 0.0 and
 * 1.0
 **/
export function getAudioVolume () {
  return volumeLevel
}

/**
 * <p>This will halt any audio currently being played by the Item Presenter.</p>
 *
 * @public
 * @method stopAudio
 **/
export function stopAudio () {
  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.getResponders(ResponderNames.AUDIO_STOP)
      .forEach(responder => {
        responder()
      })
  })
}

/**
 * This will allow the presenter to respond to keyboard events that originate
 * from outside its wrapping container, such as hotkey events that are intended
 * to trigger ACO selection.
 *
 * @public
 * @method reactToKeyEvent
 **/
export function reactToKeyEvent (e) {
  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.getResponders(ResponderNames.KEY_EVENT)
      .forEach(responder => {
        responder(e)
      })
  })
}

/**
 * <p>This will engage (or disengage) a feature specified.</p>
 *
 * @public
 * @method setFeatureEnabled
 * @param featureName {String} the name of the feature {@see features}
 * @param isEnabled {Boolean} true if this feature should be enabled, false
 * otherwise
 * @param [featureData] {Object} data appropriate to a specific feature
 */
export function setFeatureEnabled (featureName, isEnabled, featureData) {
  triggerFeatureEnablerResponders(featureName, isEnabled, featureData)

  const configurations = [lastRuntimeConfiguration, lastItemAidsPanelRuntimeConfiguration].filter(i => i)
  configurations.forEach((configuration) => {
    configuration.setFeatureEnabled(
      featureName,
      isEnabled,
      featureData
    )
  })

  if (isEnabled) {
    STATE_TRACKER.features[featureName] = featureData || {}
  } else {
    delete STATE_TRACKER.features[featureName]
  }
}

/**
 * Returns information about all product/theme combinations known to the
 * presenter
 *
 * @public
 * @method getThemeInfo
 */

export function getThemeInfo (productName) {
  const themeInfo = {}

  Object.keys(ValidThemesByProduct).forEach(product => {
    themeInfo[product] = {
      product: product,
      defaultTheme: ValidThemesByProduct[product]
        .find(t => t.identifier === DefaultThemesByProduct[product]),
      themes: ValidThemesByProduct[product].map(theme => {
        return {
          identifier: theme.identifier,
          displayName: theme.displayName,
          default: DefaultThemesByProduct[product] === theme.identifier,
        }
      }),
    }
  })

  const product = productName === ProductNames.ACADEMIC_PROFICIENCY_NWEA
    ? ProductNames.STATE : productName

  return productName ? themeInfo[product] : themeInfo
}

export function getTTSExcludeInfo (excludeGroups) {
  return excludeGroups.reduce(
    (parts, groupName) => {
      if (TTSExcludeGroups[groupName]) {
        return parts.concat(TTSExcludeGroups[groupName])
      } else {
        console.warn(`Unknown tts exclude group name: ${groupName}`)
        return parts
      }
    },
    []
  )
}

export function getCurrentZoomLevel () {
  const DEFAULT_ZOOM_LEVEL = 1

  const state = itemAidsSessionFreezer.get()
  if (state[FreezerKeys.ITEM_AIDS_PANEL] && state[FreezerKeys.ITEM_AIDS_PANEL].zoomLevel) {
    return state[FreezerKeys.ITEM_AIDS_PANEL].zoomLevel
  } else {
    return DEFAULT_ZOOM_LEVEL
  }
}

export const variables = ConfigurationVariables
export const observables = ObservableCallbacks
export const features = FeatureNames
export const itemAids = ItemAids
export const productNames = ProductNames
export const languages = Languages
export const itemParts = ItemParts
export const ttsExcludeGroupNames = TTSExcludeGroupNames
export const version = PRESENTER_VERSION
