/**
*******************************************************************************
* Licensed Materials - Property of NWEA
* Copyright NWEA 2000-2020 All Rights Reserved
*******************************************************************************
*/

/**
 * <p>These constants represent events that a Renderable would emit, and do not correspond directly to observers
 * and/or events that the StateTracker or PresenterApi expose to external users of the Item Presenter framework.</p>
 *
 * @property RenderableEvents
 */
export const RenderableEvents = {
  /**
   * <p>This event is emitted when a renderable instance has been asked to be displayed (e.g. rendered).</p>
   *
   * @event RENDER_START
   * @param renderable {RenderableBase} renderable instance being rendered
   */
  RENDER_START: 'render-start',

  /**
   * <p>This event is emitted when a renderable instance has completed its work of inserting the DOM elements
   * needed for this item to be initially displayed (e.g. rendered).</p>
   *
   * @event RENDER_FINISH
   * @param renderable {RenderableBase} renderable instance being rendered
   */
  RENDER_FINISH: 'render-finish',

  /**
   * <p>This event is emitted when a renderable instance has been asked to be remove anything it added to the
   * DOM and prepare for being removed from memory.</p>
   *
   * @event DISPOSE_START
   * @param renderable {RenderableBase} renderable instance being disposed
   */
  DISPOSE_START: 'dispose-start',

  /**
   * <p>This event is emitted when a renderable instance has completed its work of removing any DOM element it
   * created, so it is ready to be removed from memory.</p>
   *
   * @event DISPOSE_FINISH
   * @param renderable {RenderableBase} renderable instance being disposed
   */
  DISPOSE_FINISH: 'dispose-finish',

  /**
   * <p>This event is emitted whenever the content for a renderable (and all of its descendants) have completed
   * loading external resources (e.g. audio/video is ready to play and images have fully loaded).</p>
   *
   * @event CONTENT_READY
   * @param renderable {RenderableBase} renderable instance whose content has loaded
   */
  CONTENT_READY: 'content-ready',

  /**
   * <p>This event is emitted whenever the content for a renderable (or any of its descendants) have any
   * resources fail to be loaded.</p>
   *
   * @event CONTENT_LOAD_ERROR
   * @param renderable {RenderableBase} renderable instance whose content has loaded
   * @param error {*} the first error reported by the browser
   */
  CONTENT_LOAD_ERROR: 'content-load-error',

  /**
   * <p>This event is emitted when renderables are added after the initial rendering. It is called on the element
   * where the new renderables were added and propogated up to the topmost renderable in the tree.
   */
  RENDERABLES_ADDED: 'renderables-added',

  /**
   * <p>This event is emitted when renderables are removed after the initial rendering. It is called on the element
   * where the new renderables were removed and propogated up to the topmost renderable in the tree.
   */
  RENDERABLES_REMOVED: 'renderables-removed',
}

export const ItemRenderableEvents = {
  CHANGED_EASY_TARGETING_MODE: 'changed-easy-targeting-mode',
}

export const GapMatchRenderableEvents = {
  ACO_ADDED_TO_GAP: 'aco-added-to-gap',
}

export const InputRenderableEvents = {
  CHANGED: 'changed',
}

export const ProductNames = {
  MAP: 'map',
  MPG: 'mpg',
  SKILLS_NAVIGATOR: 'sn',
  OECD: 'oecd',
  STATE: 'state',
  ACADEMIC_PROFICIENCY_NWEA: 'academic_proficiency_nwea', // aka "state"
}

export const ServiceNames = {
  AUDIO_PLAYER: 'audioPlayer',
}

export const ConfigurationVariables = {
  MEDIA_PATH: 'mediaPath',
  RESOURCE_PATH: 'itemAidsResourcePath',
  ITEM_SET_NAME: 'itemSetName',
  ITEM_SET_POSITION_IN_GROUP: 'itemSetPositionInGroup',
  ITEM_SET_GROUP_SIZE: 'itemSetGroupSize',

  // When true, turns off (timers for) both ITEM_LOAD_SPINNER and ITEM_LOAD_HELP_INSTRUCTIONS
  DISABLE_SPINNER_AND_INSTRUCTIONS_TIMEOUT: 'disableSpinnerAndInstructions',

  // Delay from the item starting to load until the spinner appears, in milliseconds
  ITEM_LOAD_SPINNER_DELAY: 'itemLoadSpinnerDelay',

  // Delay from the spinner appearing until the help instructions appear, in milliseconds
  ITEM_LOAD_HELP_INSTRUCTIONS_DELAY: 'itemLoadHelpInstructionsDelay',

  LANGUAGE: 'language',
  PRODUCT_NAME: 'productName',
}

export const ObservableCallbacks = {
  /**
   * <p>A response observer will be notified of every change to the state of selection for answer options.
   * This change includes data about the state of what answer options, for each interaction section in the
   * item, are selected.  It also includes an indicator for containers to understand if this event was
   * generated in response to user interaction or programmatically generated as a result of loading an item.</p>
   *
   * <p><i>NOTE:</i> After every item is loaded (and its RENDER_FINISH event fires), any external observer
   * that was registered with the StateTracker will be notified of the current state (and possibly "restored"
   * state) of answer options.  This is currently the only mechanism that would ever generate an event that
   * is not marked as 'isStudentInitiated'.</p>
   *
   * @event RESPONSE_OBSERVER
   * @param responseData {Object}
   * @param responseData.isStudentInitiated {Boolean} true if this data was generated from a user-event,
   *                                                  false if this is the "initial-state" broadcast that
   *                                                  is emitted after first loading an item
   * @param responseData.isScorable {Boolean} true if the student's response has
   *   met minimum selection requirements.
   * @param responseData.response {{String: Array|String}} response data, keyed by the identifier for an
   *                                                       interaction, and which has values of either a
   *                                                       single string (if this was a text interaction
   *                                                       response) or an array of strings (representing
   *                                                       answer option identifiers that were selected)
   */
  RESPONSE_OBSERVER: 'responseObserver',

  /**
   * This is like the responseObserver, but for the item aids panel state.
   * When a tool button is toggled, or an item aid is enabled/disabled, the
   * itemAidsPanelObserver will be notified of the state change. The broadcast
   * object will be an array of all item aids, and an indication of their
   * current on/off state. This is meant to be a current snapshot, not a history
   */
  ITEM_AIDS_PANEL_OBSERVER: 'itemAidsPanelObserver',

  /**
   * <p>A scroll observer will be notified of every change to the scroll state of prompt and supplemental
   * elements</p>
   *
   *
   * @event SCROLL_OBSERVER
   * @param elementID {String} should be either 'supplemental' or 'prompt
   * @param maxPercentScrolledV {Integer} range 0-100
   * @param isScrollableV {Boolean} determined by whether there is a scrollbar displayed
   */
  SCROLL_OBSERVER: 'scrollObserver',

  /**
   * <p>An audio observer will be notified of every audio start and stop event (receiving separate events
   * for each individual audio that may be part of an audio list).</p>
   *
   * @event AUDIO_OBSERVER
   * @param audioEvent {String} {@see const AudioEventNames} the type of audio event being emitted
   * @param data {Object}
   * @param data.audioElement {Audio} the HTML Audio object this event relates to
   * @param [data.ordinal] {Number} the 1-based index number of this audio in the list of audio being played
   *                                (this is only included when an  audio start is announced)
   * @param [data.count] {Number} the number of audio files being played in sequence
   *                              (this is only included when an audio start is announced)
   * @param [data.wasInterrupted] {Boolean} true if this event is the result a stop audio event false otherwise
   */
  AUDIO_OBSERVER: 'audioPlayObserver',

  /**
   * Current used for QA
   */
  TEXT_TO_SPEECH_AUDIO_OBSERVER: 'textToSpeechAudioObserver',

  /**
   * <p>A key event listener will be notified of un-trapped key events that occur in the presenter-owned iframe.
   * (NOTE: only 'keydown' events are reported to the registered observer.)</p>
   *
   * <p>For more information, head to <a href="https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent">
   * the MDN KeyboardEvent API page</a>.</p>
   *
   * @event KEY_EVENT_OBSERVER
   * @param keyEventData {Object}
   * @param keyEventData.type {String} the name of this event, as reported by Event.type
   * @param keyEventData.which {Number} the key code as reported by KeyboardEvent.which
   * @param keyEventData.key {String} the string representing the key as reported by KeyboardEvent.key
   * @param keyEventData.code {String} the string representing the key as reported by KeyboardEvent.code
   * @param keyEventData.altKey {Boolean} true if an alt key was reported as pressed
   * @param keyEventData.metaKey {Boolean} true if a meta key (Command/Windows) was reported as pressed
   * @param keyEventData.ctrlKey {Boolean} true if a control key was reported as pressed
   * @param keyEventData.shiftKey {Boolean} true if a shift key was reported as pressed
   * @param keyEventData.target {DOM Element} the DOM element reported from Event.target
   */
  KEY_EVENT_OBSERVER: 'keyEventObserver',

  USER_ACTIVITY_OBSERVER: 'userActivityObserver',
  EASY_TARGETING_MODE_OBSERVER: 'easyTargetingModeObserver',

  // observers not available to any renderables and represent life-cycle events larger than individual
  // renderable instances that compose the whole experience of an item
  ITEM_INITIALIZATION_START_OBSERVER: 'itemInitializationStartObserver',
  ITEM_INITIALIZATION_FINISH_OBSERVER: 'itemInitializationFinishObserver',
  ITEM_CLEANUP_START_OBSERVER: 'itemCleanupStartObserver',
  ITEM_CLEANUP_FINISH_OBSERVER: 'itemCleanupObserver',
  ITEM_RENDERABLE_CONSTRUCTION_START_OBSERVER: 'itemRenderableConstructionStartObserver',
  ITEM_RENDERABLE_CONSTRUCTION_FINISH_OBSERVER: 'itemRenderableConstructionFinishObserver',
  ITEM_DOM_LOAD_START_OBSERVER: 'itemDomLoadStartObserver',
  ITEM_DOM_LOAD_FINISH_OBSERVER: 'itemDomLoadFinishObserver',
  ITEM_CONTENT_LOAD_FINISH_OBSERVER: 'itemContentLoadFinishObserver',
  ITEM_CONTENT_LOAD_ERROR_OBSERVER: 'itemContentLoadErrorObserver',
  ITEM_RUNTIME_ERROR_OBSERVER: 'itemRuntimeErrorObserver',
}

export const ResponderNames = {
  AUDIO_STOP: 'audioStop',
  AUDIO_VOLUME_CHANGE: 'audioVolumeChange',
  KEY_EVENT: 'keyEvent',
  FEATURE_ENABLER: 'featureEnabler',
  CONTENT_READY: 'contentReady',
  MARKUP_CHANGED: 'markupChanged',
  MARKUP_VISIBILITY_CHANGED: 'markupVisbilityChanged',
  TTS_PARSED: 'ttsParsed',
}

export const AudioEventNames = {
  AUDIO_START: 'audioStart',
  AUDIO_END: 'audioEnd',
}

export const FeatureNames = {
  SELECTABLE_ITEM_TEXT: 'selectableItemText',
  ENABLE_TTS_MARKUP: 'enableTTSMarkup',
  SILENCE_TTS_ITEM_PARTS: 'silenceTTSItemParts',
  SCREEN_READER_MARKUP: 'screenReaderMarkup',
  EASY_TARGETING_MODE: 'easyTargetingMode',
  DISABLE_AUDIO: 'disableAudio',
  DISABLE_TEXT_CURSOR: 'disableTextCursor',
  DISABLE_ANIMATIONS: 'disableAnimations',
  ENABLE_ITEM_AIDS_PANEL_KEYBOARD_NAV: 'enableItemAidsPanelKeyboardNav',
}

export const ItemAids = {
  DESMOS_CALCULATOR: 'desmosCalculatorItemAid',
  DRIVER: 'driverItemAid',
  HIGHLIGHTER: 'highlighterItemAid',
  TEXT_TO_SPEECH: 'ttsItemAid',
  ELIM: 'elimItemAid',
  LINE_READER: 'lineReaderItemAid',
  NOTEPAD: 'notepadItemAid',
  MEASUREMENT: 'measurementItemAid',
  ZOOM: 'zoomItemAid',
  IMAGE_VIEWER: 'imageViewerItemAid',
  VOLUME: 'volumeItemAid',
}

export const ItemAidsInitOrder = [
  // These modify item markup, so they should go first
  ItemAids.ELIM,
  ItemAids.TEXT_TO_SPEECH,

  // This one is just weird, so it's going early
  ItemAids.ZOOM,

  // Meow
  ItemAids.DRIVER,

  // These are just meh
  ItemAids.DESMOS_CALCULATOR,
  ItemAids.LINE_READER,
  ItemAids.MEASUREMENT,
  ItemAids.IMAGE_VIEWER,
  ItemAids.NOTEPAD,
  ItemAids.VOLUME,

  // These need item markup changes to be done by the time they initialize
  ItemAids.HIGHLIGHTER,
]

export const ItemAidExternalToolNames = {
  [ItemAids.DESMOS_CALCULATOR]: 'DESMOS_CALCULATOR',
  [ItemAids.HIGHLIGHTER]: {
    highlighter: 'HIGHLIGHTER',
    eraser: 'ERASER',
  },
  [ItemAids.TEXT_TO_SPEECH]: {
    clickPlay: 'TTS_CLICK_PLAY',
    play: 'TTS_PLAY',
    pause: 'TTS_PAUSE',
    volume: 'TTS_VOLUME',
  },
  [ItemAids.ELIM]: 'ELIM',
  [ItemAids.LINE_READER]: 'LINE_READER',
  [ItemAids.NOTEPAD]: 'NOTEPAD',
  [ItemAids.MEASUREMENT]: {
    ruler1: 'RULER1',
    ruler2: 'RULER2',
    protractor: 'PROTRACTOR',
  },
  [ItemAids.ZOOM]: {
    zoomOut: 'ZOOM_OUT',
    zoomIn: 'ZOOM_IN',
  },
  [ItemAids.IMAGE_VIEWER]: 'REFERENCE',
  [ItemAids.VOLUME]: 'VOLUME',
}

// the values of KeyboardEvent.code that trigger the item aids
export const ItemAidHotKeys = {
  UNIVERSAL_CLOSE_KEY: 'KeyX', // close all item aids
  ITEM_AIDS_PANEL: 'KeyT',
  [ItemAids.DESMOS_CALCULATOR]: 'KeyC',
  [ItemAids.HIGHLIGHTER]: {
    highlighter: 'KeyH',
    eraser: 'KeyE',
  },
  [ItemAids.TEXT_TO_SPEECH]: undefined,
  [ItemAids.ELIM]: 'KeyA',
  [ItemAids.LINE_READER]: 'KeyL',
  [ItemAids.NOTEPAD]: 'KeyN',
  [ItemAids.MEASUREMENT]: {
    ruler1: 'KeyR',
    ruler2: 'KeyR',
    protractor: 'KeyP',
  },
  [ItemAids.ZOOM]: {
    zoomOut: 'Minus',
    zoomIn: 'Equal',
  },
  [ItemAids.IMAGE_VIEWER]: 'KeyF',
  [ItemAids.VOLUME]: undefined,
}

export const FreezerKeys = {
  ITEM: 'item',
  ITEM_AIDS_PANEL: 'itemAidsPanel',
}

export const noopFunction = () => {}

export const InteractionNames = {
  COMPOSITE: 'compositeInteraction',
  CHOICE: 'choiceInteraction',
  CHOICE_MULTIPLE: 'choiceMultipleInteraction',
  EXTENDED_TEXT: 'extendedTextInteraction',
  GAP_MATCH: 'gapMatchInteraction',
  GRAPHIC_GAP_MATCH: 'graphicGapMatchInteraction',
  TEXT_ENTRY: 'textEntryInteraction',
  HOT_TEXT: 'hotTextInteraction',
  SELECTABLE_TEXT: 'selectableTextInteraction',
}

export const NavigationDirections = {
  NONE: 0,       // 0, 0b0000
  LEFT: 1 << 0,  // 1, 0b0001
  RIGHT: 1 << 1, // 2  0b0010
  UP: 1 << 2,    // 4  0b0100
  DOWN: 1 << 3,  // 8  0b1000
}

export const RotationDirections = {
  NONE: 0,              // 0, 0b00
  ROTATE_LEFT: 1 << 0,  // 1, 0b01
  ROTATE_RIGHT: 1 << 1, // 2, 0b10
}

// use standard locale strings
export const Languages = {
  ENGLISH: 'en',
  SPANISH: 'es',
  SPANISH_SPANISH: 'es-ES',
  SPANISH_MEXICAN: 'es-MX',
}

// these are parts that will be passed in for configuring TTS to ignore
export const ItemParts = {
  DIRECTIONS: 'directions',
  SUPPLEMENTAL: 'supplemental',
  PASSAGE: 'passages',
  PROMPT: 'stem',
  STEM: 'stem',
}

// class names of the divs that contain the interaction area for items.
// Note: choiceInteraction doesn't have this, only a prompt and ACOs
export const InteractionClassNames = [
  'interactionArea', // gap and GGM
  'hotTextInteraction',
  'textInteraction',
  'extendedTextInteraction',
]

export const TTSExcludeGroupNames = {
  CONTENT: 'content',
  ACOS: 'acos',
  DIRECTIONS: ItemParts.DIRECTIONS,
  SUPPLEMENTAL: ItemParts.SUPPLEMENTAL,
  PASSAGE: ItemParts.PASSAGE,
  PROMPT: ItemParts.PROMPT,
  STEM: ItemParts.STEM,
}

export const TTSExcludeGroups = {
  [TTSExcludeGroupNames.CONTENT]: InteractionClassNames.concat([ItemParts.SUPPLEMENTAL, ItemParts.PASSAGE]),
  [TTSExcludeGroupNames.ACOS]: ['multipleChoice', 'gapText'],
  [TTSExcludeGroupNames.DIRECTIONS]: [ItemParts.DIRECTIONS],
  [TTSExcludeGroupNames.SUPPLEMENTAL]: [ItemParts.SUPPLEMENTAL, ItemParts.PASSAGE],
  [TTSExcludeGroupNames.PASSAGE]: [ItemParts.SUPPLEMENTAL, ItemParts.PASSAGE],
  [TTSExcludeGroupNames.PROMPT]: [ItemParts.PROMPT],
  [TTSExcludeGroupNames.STEM]: [ItemParts.STEM],
}

// Note: this DOES imply that a single "theme" can be valid for multiple
// products, if that does anything for you...
// a null identifier in this case corresponds to "use the base styles without
// any additional themes".
export const ValidThemesByProduct = {
  [ProductNames.MAP]: [
    { identifier: 'map-classic', displayName: 'MAP Classic' },
    { identifier: 'map-steel', displayName: 'Steel' },
  ],
  [ProductNames.MPG]: [
    { identifier: 'mpg-classic', displayName: 'MAP Classic K-2' },
    { identifier: 'mpg-steel', displayName: 'Steel K-2' },
  ],
  [ProductNames.SKILLS_NAVIGATOR]: [
    { identifier: null, displayName: 'Skills Navigator' },
  ],
  [ProductNames.OECD]: [
    { identifier: null, displayName: 'OECD' },
  ],
  [ProductNames.STATE]: [
    { identifier: null, displayName: 'Academic Proficiency' },
  ],
}

// this should correlate to one of the identifiers above.
export const DefaultThemesByProduct = {
  [ProductNames.MAP]: 'map-steel',
  [ProductNames.MPG]: 'mpg-steel',
  [ProductNames.SKILLS_NAVIGATOR]: null,
  [ProductNames.OECD]: null,
  [ProductNames.STATE]: null,
}

// We manage tabindex values to accommodate Test Player tabbing business rules.
// Once we cut over to test-player-ui, we may want to revisit all this tabindex
// stuff
export const TabIndexValues = {
  STARTING: 1000,
  IFRAME_CONTAINER: 1000,
  ITEM_AIDS_PANEL: 501,
  MOVABLE_ITEM_AID: 510,
}
