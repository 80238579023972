/**
*******************************************************************************
* Licensed Materials - Property of NWEA
* Copyright NWEA 2000-2020 All Rights Reserved
*******************************************************************************
*/

import lodashAssign from 'lodash/assign'
import lodashKebabCase from 'lodash/kebabCase'
import lodashDifference from 'lodash/difference'
import lodashSortBy from 'lodash/sortBy'
import lodashTransform from 'lodash/transform'
import lodashUniq from 'lodash/uniq'
import lodashUniqBy from 'lodash/uniqBy'

import { ConfigurationVariables, InteractionNames, Languages } from '../renderer-constants'

const INTERACTION_COMPONENT_INFO =
  'NWEA-ASSESSMENT-ITEM-PRESENTER-INTERACTION-AND-COMPONENT-VERSION-INFO-PLACEHOLDER-TO-BE-REPLACED-IN-BUILD-PROCESS'
const ITEM_AID_COMPONENT_INFO =
  'NWEA-ASSESSMENT-ITEM-PRESENTER-ITEM-AID-INFO-PLACEHOLDER-TO-BE-REPLACED-IN-BUILD-PROCESS'
const THEME_COMPONENT_INFO =
  'NWEA-ASSESSMENT-ITEM-PRESENTER-THEME-INFO-PLACEHOLDER-TO-BE-REPLACED-IN-BUILD-PROCESS'
const CDN_SERVER_PATH = 'NWEA-ASSESSMENT-ITEM-PRESENTER-CDN-SERVER-PATH'

const DATA_ITEM_PRESENTER_MARKER_ATTRIBUTE = 'data-item-presenter-marker'
const INJECTED_MARKER_VALUE = 'injected-reference'
const MANIFEST_STRINGS_FOR_JS_AND_CSS = ['js', 'css']

export const RESOURCE_PATH = `${CDN_SERVER_PATH}/${INTERACTION_COMPONENT_INFO.versionPath}`
export const PRESENTER_VERSION = INTERACTION_COMPONENT_INFO.versionPath

/**
 * <p>This internal method will return a list of file dependencies appropriate
 * to the item represented by the input itemDefinitionModel. If the input item
 * is not composite, then a copy of the data from the manifest information in
 * INTERACTION_COMPONENT_INFO is returned. If the input IS COMPOSITE, then this
 * function will aggregate all the dependencies together into a single object
 * that is still keyed by file type. If the passed item contains a graph, desmos
 * calculator will be added to the js dependencies if not already present.</p>
 *
 * @private
 * @method getManifestForItem
 * @param itemDefinitionModel {ItemDefinitionModel}
 * @returns {Object} returns an object keyed by file type with arrays of depedent file names
 **/
export function getManifestForItem (itemDefinitionModel, product) {
  const interactionFactoryName = lodashKebabCase(itemDefinitionModel.getInteractionFactoryName())
  const manifest = lodashAssign(
    {},
    INTERACTION_COMPONENT_INFO.dependencies[interactionFactoryName][product]
  )

  if (interactionFactoryName === lodashKebabCase(InteractionNames.COMPOSITE)) {
    itemDefinitionModel.interactionNames
      .map(itemDefinitionModel.interactionFactoryNameForInteractionName)
      .map(lodashKebabCase)
      .forEach(interactionName => {
        const interactionDependencies = INTERACTION_COMPONENT_INFO
          .dependencies[interactionName][product]

        Object.keys(interactionDependencies).forEach(fileType => {
          manifest[fileType] = lodashUniq((manifest[fileType] || [])
            .concat(interactionDependencies[fileType]))
        })
      })
  }

  if (itemDefinitionModel.hasGraph) {
    if (!manifest.js) {
      manifest.js = []
    }

    if (!manifest.js.some(({ id }) => id === 'calculator')) {
      manifest.js.push({
        id: 'calculator',
        npmModule: '@nwea/desmos-calculator',
        file: 'calculator.js',
      })
    }
  }

  return manifest
}

export function getManifestForItemAids (itemAidsPanelModel) {
  const manifest = {}
  const invalidItemAids = []

  itemAidsPanelModel.itemAids.concat(itemAidsPanelModel.container).forEach((itemAid) => {
    const itemAidName = lodashKebabCase(itemAid.name)
    const product = itemAidsPanelModel.product
    if (ITEM_AID_COMPONENT_INFO[itemAidName] && ITEM_AID_COMPONENT_INFO[itemAidName][product]) {
      const itemAidDependencies = ITEM_AID_COMPONENT_INFO[itemAidName][product]
      Object.keys(itemAidDependencies).forEach(fileType => {
        manifest[fileType] = lodashUniq((manifest[fileType] || []).concat(itemAidDependencies[fileType]))
      })
    } else {
      console.error(`Item Aid ${itemAid.name} doesn't exist for product ${product}.`)
      invalidItemAids.push(itemAid.name)
    }
  })

  itemAidsPanelModel.itemAids = itemAidsPanelModel.itemAids.filter(itemAid => {
    return !invalidItemAids.includes(itemAid.name)
  })

  return manifest
}

export function getManifestForTheme (themeDefinitionModel) {
  if (!themeDefinitionModel.theme) {
    return
  }

  let manifest = {}

  if (THEME_COMPONENT_INFO[themeDefinitionModel.theme]) {
    manifest = THEME_COMPONENT_INFO[themeDefinitionModel.theme]
  } else {
    console.error(`Unknown theme ${themeDefinitionModel.theme}`)
  }

  if (!manifest.css) {
    manifest.css = []
  }

  // We're inserting the generated stylesheet in to the manifest here so that
  // the loadExecutor can insert it.
  manifest.css.push(
    { file: `themes/${themeDefinitionModel.theme}.css` }
  )

  return manifest
}

/**
 * <p>This method returns the correct URL for the bundled Javascript, and generated CSS, appropriate to the version of
 * components against which this item was built.  <b>NOTE:</b> If we ever introduce multiple component versions, this
 * is the function in which we would have logical branches that returned an appropriate paths.</p>
 *
 * <p>For an example of what the INTERACTION_COMPONENT_INFO will actually be, an example can be found
 * in the docs for the method "getLatestVersionAndInteractionManifestInfo" of <i>build/server-packaging/component-packager.js</i>.</p>
 *
 * @private
 * @method getInteractionInjectableElementConfigurations
 * @param manifest {Object} an object keyed by file type with arrays of dependent file names
 * @returns {[ [Object] ]} an array of configuration object arrays, grouped and ordered by 'loadOrder'
 */
export function getInteractionInjectableElementConfigurations (manifest, basePath, runtimeConfiguration) {
  const LANGUAGE = runtimeConfiguration
    .getVariable(ConfigurationVariables.LANGUAGE) || Languages.ENGLISH

  const injectableElementConfigurations = []
  MANIFEST_STRINGS_FOR_JS_AND_CSS.forEach(fileType => {
    // lodash function that will return objects from that manifest that have
    // have unique resources, either filenames, or src urls, so the appropriate
    // tags can be injected into the dom, without duplicates
    lodashUniqBy(manifest[fileType], (d) => {
      return d.file + d.id
    }).forEach(dependencyData => {
      // if bound variable (aka jQuery) already exists don't load it again
      const boundVariableExists =
        !!(dependencyData.boundVariable && window[dependencyData.boundVariable])
      if (boundVariableExists) {
        return
      }

      // if a dependency requires a specific language, check it here
      if (dependencyData.language && dependencyData.language !== LANGUAGE) {
        return
      }

      const URI = `${basePath}/${fileType}/${dependencyData.file}`

      const injectableConfig = {
        id: [fileType, dependencyData.file || dependencyData.id].join('-')
          .replace(/\.[^.]+$/g, '') // delete file extension from id
          .replace(/\./g, '-'), // replacing dots with hyphens is a good idea
        loadOrder: dependencyData.loadOrder || 1,
      }

      injectableConfig.attributes = Object.assign(
        {},
        dependencyData.domAttributes,
        { 'data-item-presenter-marker': 'injected-reference' },
      )

      if (fileType === 'js') {
        injectableConfig.element = 'script'
        injectableConfig.attributes.type = 'text/javascript'
        injectableConfig.attributes.src = dependencyData.src || URI
        if (!dependencyData.src) {
          injectableConfig.attributes.crossorigin = 'anonymous'
        }
        if (dependencyData.language) {
          injectableConfig.language = dependencyData.language
        }
      } else {
        injectableConfig.element = 'link'
        injectableConfig.attributes.href = dependencyData.src || URI
        injectableConfig.attributes.rel = 'stylesheet'
      }

      injectableElementConfigurations.push(injectableConfig)
    })
  })

  return injectableElementConfigurations
}

/**
 * <p>This returns an array of all ids found in the <i>head</i> section of the document that have the tell-tale marker
 * of being injected by the renderer-api.</p>
 *
 * @private
 * @method getInjectedElementIds
 * @param containerDocument {HTMLElement} a document being managed by the presenter api
 * @param allowedTagNames [{String}] a list of allowed tag names
 * @returns {Array}
 */
export function getInjectedElementIds (containerDocument, allowedTagNames) {
  const htmlNodes = containerDocument.head.querySelectorAll(
    `[${DATA_ITEM_PRESENTER_MARKER_ATTRIBUTE}="${INJECTED_MARKER_VALUE}"]`
  )
  const foundIds = []

  for (let elementIndex = 0; elementIndex < htmlNodes.length; ++elementIndex) {
    const node = htmlNodes[elementIndex]
    if (!allowedTagNames || allowedTagNames.indexOf(node.tagName) >= 0) {
      foundIds.push(htmlNodes[elementIndex].id)
    }
  }

  return foundIds
}

/**
 * <p>Creates a new DOM element, per the "injectable element configuration" specification, adds it to the DOM, and
 * registers load/error hooks on the created element. This returns a promise that will be resolved if the created
 * DOM element is loaded, and rejected with a string citing which file failed to load if there was an error.</p>
 *
 * @private
 * @method loadDomElementFromInjectableConfig
 * @param containerDocument {HTMLElement} a document being managed by the presenter api
 * @param injectableElementConfiguration {{ element: String, id: String, attributes: Object }}
 * @returns {Promise}
 */
export function loadDomElementFromInjectableConfig (containerDocument, injectableElementConfiguration) {
  const newElement = containerDocument.createElement(injectableElementConfiguration.element)

  return new Promise((resolve, reject) => {
    const speechStreamEventHandler = (e) => {
      resolve()
      window.removeEventListener('speechToolbarLoaded', speechStreamEventHandler)
    }

    newElement.id = injectableElementConfiguration.id
    for (const attributeKey in injectableElementConfiguration.attributes) {
      newElement.setAttribute(attributeKey, injectableElementConfiguration.attributes[attributeKey])
    }

    if (['js-speechstream-config-en', 'js-speechstream-config-es'].includes(newElement.id)) {
      window.addEventListener('speechToolbarLoaded', speechStreamEventHandler)
    } else {
      newElement.addEventListener('load', () => resolve())
    }
    containerDocument.head.appendChild(newElement)
    newElement.addEventListener('error', errorEvent => {
      newElement.parentNode.removeChild(newElement)
      const url = injectableElementConfiguration.attributes.src || injectableElementConfiguration.attributes.href
      const ajax = new XMLHttpRequest()

      ajax.timeout = 10 * 1000

      function rejectWithMessage (message) {
        // eslint-disable-next-line max-len
        reject(new Error(`Failed to load (+ ${message}): ${url} ${ajax.status} ${ajax.statusText} ${ajax.getAllResponseHeaders()}`))
      }

      ajax.addEventListener('error', () => {
        rejectWithMessage('AJAX error')
      })
      ajax.addEventListener('load', () => {
        if (ajax.status === 200 || ajax.status === 304) {
          rejectWithMessage('AJAX load, succeeded')
        } else {
          rejectWithMessage('AJAX load, failed')
        }
      })
      ajax.addEventListener('timeout', () => {
        rejectWithMessage('AJAX timeout')
      })
      ajax.open('GET', url)
      ajax.send()
    })
  })
}

/**
 * <p>This is the work-horse of the API for item-aids resource loading, which will leverage other helper methods defined
 * above to assure that all the needed Javascript and CSS files for the provided <i>itemAidsConfiguration</i> are
 * loaded.</p>
 *
 * <p>This returns a promise that will be resolved once <b>ALL</b> newly loaded files have successfully loaded, or
 * rejected if any of those new DOM elements had an error.</p>
 *
 * @private
 * @method loadItemAidsExecutor
 * @param containerDocument {HTMLElement} a document being managed by the presenter api
 * @param containerWindow {HTMLElement} a window being managed by the presenter api
 * @param itemAidsConfiguration {ItemAidsConfiguration}
 * @returns {Promise}
 */
export function loadItemAidsExecutor (containerDocument, containerWindow, itemAidsPanelModel, runtimeConfiguration) {
  const manifest = getManifestForItemAids(itemAidsPanelModel)
  return loadExecutor(containerDocument, containerWindow, manifest, RESOURCE_PATH, runtimeConfiguration)
}

/**
 * <p>This is the work-horse of the API for item resource loading, which will leverage other helper methods defined
 * above to assure that all the needed Javascript and CSS files for the provided <i>itemDefinitionModel</i> are
 * loaded.</p>
 *
 * <p>At its core this just a pretty basic "diff-ing" algorithm that compares the DOM to prospective element
 * definitions.</p>
 *
 * <p>This returns a promise that will be resolved once <b>ALL</b> newly loaded files have successfully loaded, or
 * rejected if any of those new DOM elements had an error.</p>
 *
 * @private
 * @method loadItemExecutor
 * @param containerDocument {HTMLElement} a document being managed by the presenter api
 * @param containerWindow {HTMLElement} a window being managed by the presenter api
 * @param itemDefinitionModel {ItemDefinitionModel}
 * @returns {Promise}
 */
export function loadItemExecutor (containerDocument, containerWindow, itemDefinitionModel,
  themeDefinitionModel, runtimeConfiguration) {
  const itemManifest = getManifestForItem(itemDefinitionModel, themeDefinitionModel.product)

  // we're going to inject the theme dependencies in to the item manifest so
  // that the theme can drive the "product" (base) styling, and so that the
  // load order can be managed.
  const themeManifest = getManifestForTheme(themeDefinitionModel)

  if (themeManifest) {
    itemManifest.css = itemManifest.css.concat(themeManifest.css || [])
    itemManifest.images = itemManifest.images.concat(themeManifest.images || [])
    itemManifest.font = itemManifest.font.concat(themeManifest.font || [])
  }
  return loadExecutor(containerDocument, containerWindow, itemManifest, RESOURCE_PATH, runtimeConfiguration)
}

function loadExecutor (containerDocument, containerWindow, manifest, basePath, runtimeConfiguration) {
  const injectableElementConfigurations = getInteractionInjectableElementConfigurations(manifest, basePath, runtimeConfiguration)

  // get the lists of ids depended on and the ones already in the DOM
  const neededInjectedElementIds = injectableElementConfigurations.map(config => config.id)
  const foundInjectedElementIds = getInjectedElementIds(containerDocument)
  const foundInjectedLinkIds = getInjectedElementIds(containerDocument, ['LINK'])

  // now we compare the set of ids specified by the dependencies and the ones
  // actually in the DOM to generate the lists of ids of the ones that are brand
  // new (to be added).  We also always want to remove/re-add the theme
  // stylesheets to ensure that they are always applied after all base styles.
  const themeIds = foundInjectedLinkIds.filter(l => l.includes('themes'))
  const idsToRemove = lodashDifference(foundInjectedLinkIds, neededInjectedElementIds)
    .concat(lodashDifference(foundInjectedElementIds, neededInjectedElementIds))
    .filter((id) => id.substring(0, 3) === 'css') // only remove css files, TP#136075
    .concat(themeIds)
    .filter((t, idx, self) => self.indexOf(t) === idx) // unique things to remove

  const idsToAdd = lodashDifference(neededInjectedElementIds, foundInjectedElementIds)
    .concat(themeIds)

  // as a convenience so we can just assume that promiseChain always has a promise, we create an pre-resolved promise
  let promiseChain = Promise.resolve(true)
  // we would like to group dependencies of the same "loadOrder" together (a load group) and order those groups
  // based on their ascending "loadOrder", which is what this accomplished
  const injectableElementLoadGroups = lodashTransform(
    lodashSortBy(injectableElementConfigurations, c => c.loadOrder),
    (result, configuration) => {
      if (result.length === 0) {
        result.push([configuration])
      } else {
        if (result[result.length - 1][0].loadOrder === configuration.loadOrder) {
          result[result.length - 1].push(configuration)
        } else {
          result.push([configuration])
        }
      }
    },
    []
  )

  idsToRemove.forEach(id => {
    containerDocument.head.removeChild(containerDocument.getElementById(id))
  })

  injectableElementLoadGroups.forEach(loadGroup => {
    promiseChain = promiseChain.then(() => {
      return Promise.all(
        loadGroup.map(newElementConfiguration => {
          if (idsToAdd.indexOf(newElementConfiguration.id) !== -1) {
            return loadDomElementFromInjectableConfig(containerDocument, newElementConfiguration)
          }

          const matchingElementInDom = containerDocument.getElementById(newElementConfiguration.id)

          const shouldRebootElement =
            (matchingElementInDom.nodeName === 'SCRIPT' &&
              matchingElementInDom.src !== newElementConfiguration.attributes.src) ||
            (matchingElementInDom.nodeName === 'LINK' &&
              matchingElementInDom.href !== newElementConfiguration.attributes.href)

          if (shouldRebootElement) {
            containerDocument.head.removeChild(matchingElementInDom)
            return loadDomElementFromInjectableConfig(containerDocument, newElementConfiguration)
          } else {
            return Promise.resolve(true)
          }
        })
      )
    })
  })

  if (!containerWindow.fontFaceObserverLoad) {
    containerWindow.fontFaceObserverLoad = fontName => {
      // timeout value is 20 seconds = 12 seconds for default user notification,
      // plus a little more to allow for slow connections
      const LOAD_TIMEOUT = 20000
      return new containerWindow.FontFaceObserver(fontName).load(null, LOAD_TIMEOUT)
        .catch(function (e) {
          throw new Error(`Font failed to load in ${LOAD_TIMEOUT}ms: ${e.family}`)
        })
    }
  }

  if (manifest.font && manifest.font.length > 0) {
    // going in reverse order, this is the final step that will "merge" all of the individual font wait promises
    // into a single one, with Promise.all(), and then chain that to our loading promises so that font-face processing
    // should be guaranteed to have completed before we declare that item Javascript is ready to be rendered
    return promiseChain.then(() =>
      Promise.all(
        // this will unique the font family names returned after the ...filter.map below
        lodashUniq(
          manifest.font
            // for each "font" dependency, we will check to see if its presence should be enforced before rendering...
            .filter(f => f.hasOwnProperty('enforcePresenceOfFamily'))
            // ...and then pluck out the font family name to enforce
            .map(f => f.enforcePresenceOfFamily)
        )
          // then we convert that uniqued list into a set of promises
          .map(containerWindow.fontFaceObserverLoad)
      )
    )
  }

  return promiseChain
}
