/**
*******************************************************************************
* Licensed Materials - Property of NWEA
* Copyright NWEA 2000-2020 All Rights Reserved
*******************************************************************************
*/

import lodashGet from 'lodash/get'
import { ProductNames } from './renderer-constants'

const KNOWN_TWO_PANEL_VIEW_CLASSES = ['twoPanelViewStimulusRight', 'twoPanelView']
const KNOWN_TWO_PANEL_VIEW_SIZES = [30, 40, 50, 57, 60, 70]
const STANDARD_OECD_ITEM_PART_NAMES = ['itemInfo', 'directions', 'supplemental', 'prompt']
const STANDARD_MAP_ITEM_PART_NAMES = ['directions', 'supplemental', 'prompt']

function __ensureSubParts (itemParts) {
  return itemParts.map(part => {
    let updatedPart = part

    if (typeof part.value === 'object' && part.value !== null && part.value.hasOwnProperty('length')) {
      updatedPart = Object.assign({ subParts: part.value }, part)
      updatedPart.value = ''
    }

    return updatedPart
  })
}

function __parseV1Json (itemDefinition) {
  const IS_OECD = this._itemConfig.product.toUpperCase() === 'OECD'
  const ITEM_LAYOUT_CLASS = lodashGet(itemDefinition, ['item', 'attributes', 'class'], '')

  // due to the splice down below, it is important that itemPartNames be a new array, not a reference to the constants,
  // which is accomplished here with the funny-lookin' `.map(name => name)` business.
  const itemPartNames = (IS_OECD ? STANDARD_OECD_ITEM_PART_NAMES : STANDARD_MAP_ITEM_PART_NAMES).map(name => name)
  const interactionPartNames = Object.keys(itemDefinition).filter(partName => partName.includes('Interaction'))

  if (lodashGet(itemDefinition, ['prompt', 'attributes', 'class']) === 'swapStemAndSuppContent') {
    itemPartNames.push(itemPartNames.splice(-2, 1)[0])
  }

  this._itemPartDefinitions = __ensureSubParts(
    itemPartNames
      .concat(interactionPartNames)
      .map(partName => itemDefinition[partName])
      .filter(part => typeof part !== 'undefined')
  )

  this._itemConfig.rendererComponentVersion = lodashGet(itemDefinition, [
    'item',
    'attributes',
    'rendererComponentVersion',
  ])
  this._itemConfig.interactionClass = lodashGet(itemDefinition, ['interaction', 'attributes', 'class'], '')
  this._itemConfig.isTwoPanelView = KNOWN_TWO_PANEL_VIEW_CLASSES.includes(ITEM_LAYOUT_CLASS)
  this._itemConfig.interactionNames = [itemDefinition.interactionType]
  this._audio = itemDefinition.audio || []
  this._imageLongDescriptions = itemDefinition.imageLongDescriptions
  this._itemNumber = itemDefinition.itemNumber
}

function __parseV2Json (itemDefinition) {
  const ITEM_LAYOUT_CLASS = lodashGet(itemDefinition, ['itemOptions', 'attributes', 'itemLayout'])

  this._itemPartDefinitions = (itemDefinition.itemParts || [])
    .filter(p => p.name !== 'itemInfo' || ProductNames.OECD === this._itemConfig.product)

  this._itemConfig.rendererComponentVersion = lodashGet(itemDefinition, [
    'itemOptions',
    'attributes',
    'rendererComponentVersion',
  ])
  this._itemConfig.isTwoPanelView = KNOWN_TWO_PANEL_VIEW_CLASSES.includes(ITEM_LAYOUT_CLASS)
  if (KNOWN_TWO_PANEL_VIEW_SIZES.includes(itemDefinition.itemOptions.attributes.twoPanelViewSplit)) {
    this._itemConfig.twoPanelViewSplit = itemDefinition.itemOptions.attributes.twoPanelViewSplit
  }

  this._itemConfig.interactionNames = this._itemPartDefinitions
    .map(p => p.name)
    .filter(name => name.includes('Interaction'))
  this._audio = itemDefinition.audio || []
  this._imageLongDescriptions = itemDefinition.imageLongDescriptions
  this._itemNumber = itemDefinition.itemNumber
}

/**
 * <p><strong>NOTE:</strong> this constants must match the camel-cased
 * directory name that houses the composite interaction
 * factory code. The <i>renderer-api</i> assumes this is true for all values
 * returned from <pre>interactionNames</pre> and
 * <pre>getInteractionFactoryName()</pre>.</p>
 *
 * @property {String} COMPOSITE_INTERACTION_NAME
 **/
export const COMPOSITE_INTERACTION_NAME = 'compositeInteraction'

export default class ItemDefinitionModel {
  constructor (itemDefinition, product) {
    if (!product) {
      throw new Error('A product must be specified when creating the ItemDefinitionModel.')
    }

    this._itemPartDefinitions = []
    this._imageLongDescriptions = []
    this._audio = []
    this._itemConfig = {
      isTwoPanelView: false,
      twoPanelViewSplit: undefined,
      rendererComponentVersion: '',
      interactionNames: [],
      product: product.toLowerCase(),
    }

    // We like STATE better so we swap to it internally when we parse items
    if (this._itemConfig.product === ProductNames.ACADEMIC_PROFICIENCY_NWEA) {
      this._itemConfig.product = ProductNames.STATE
    }
    this.parse(itemDefinition)
  }

  parse (itemDefinition) {
    if (!itemDefinition.payloadVersion || parseInt(itemDefinition.payloadVersion) === 1) {
      __parseV1Json.call(this, itemDefinition)
    } else if (parseInt(itemDefinition.payloadVersion) === 2 || parseInt(itemDefinition.payloadVersion) === 3) {
      // two and three can share a code path, the presenter is agnostic to the
      // differences
      __parseV2Json.call(this, itemDefinition)
    } else {
      throw new Error('The "payloadVersion" must be absent, "1", "2", or "3" but was: ' + itemDefinition.payloadVersion)
    }
  }

  getInteractionClass (interactionItemPart) {
    // all interactions have at least 2 class applied:
    //    .interactionContainer and .<interactionName>Container
    const baseClass = `interactionContainer ${interactionItemPart.name}Container`
    const additionalClasses = this._itemConfig.interactionClass ||
        lodashGet(interactionItemPart, ['attributes', 'class'], '')
    return `${baseClass} ${additionalClasses}`.trimEnd()
  }

  getInteractionFactoryName () {
    if (this._itemConfig.interactionNames.length === 1) {
      if (this._itemConfig.interactionNames[0] === 'graphicGapMatchInteraction') {
        // unify the gap match interactions
        return 'gapMatchInteraction'
      } else {
        return this._itemConfig.interactionNames[0]
      }
    } else {
      return COMPOSITE_INTERACTION_NAME
    }
  }

  interactionFactoryNameForInteractionName (interactionName) {
    if (interactionName === 'graphicGapMatchInteraction') {
      return 'gapMatchInteraction'
    } else {
      return interactionName
    }
  }

  get itemPartDefinitions () {
    return this._itemPartDefinitions
  }

  get isTwoPanelView () {
    return this._itemConfig.isTwoPanelView
  }

  get twoPanelViewSplit () {
    return this._itemConfig.twoPanelViewSplit
  }

  get itemNumber () {
    return this._itemNumber
  }

  get product () {
    return this._itemConfig.product
  }

  get rendererComponentVersion () {
    return this._itemConfig.rendererComponentVersion
  }

  get interactionNames () {
    return this._itemConfig.interactionNames
  }

  get audio () {
    return this._audio
  }

  get imageLongDescriptions () {
    return this._imageLongDescriptions
  }

  get isComposite () {
    return this._itemConfig.interactionNames.length > 1
  }

  get hasGraph () {
    return this.itemPartDefinitions.some(({ value }) => {
      return value && value.match(/data-graph-state=".*?"/) !== null
    })
  }
}
